import React from 'react';
import { Container } from 'react-bootstrap';

import Footer from 'component/Footer';

const MainLayout: React.FC<{ footer?: true; className?: string }> = ({
  footer,
  className,
  children
}) => {
  let classList = 'mw-100 pt-3';
  className && (classList += ` ${className}`);
  footer && (classList += ' with-footer');

  return (
    <div className="app-wrapper">
      <Container className={classList}>{children}</Container>
      {footer && <Footer />}
    </div>
  );
};
export default MainLayout;
