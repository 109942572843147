import React from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import FullHeightLayout from 'layout/FullHeightLayout';
import VDError from './VDError';
import PageTitle from 'component/PageTitle';
import StatusImage from 'component/StatusImage';
import { LinkButton } from 'component/Button';

const RegisterSuccess: React.FC = () => {
  return (
    <>
      <Row className="flex-grow-1 align-items-center">
        <Col md={7} className="mx-auto">
          <StatusImage type="cardSuccess" text="会員登録が完了しました" />
        </Col>
      </Row>
      <Row className="bottom-fix mb-3">
        <Col md={7} className="mx-auto">
          <Stack>
            <LinkButton link="/" text="ホームへ" />
          </Stack>
        </Col>
      </Row>
    </>
  );
};

/** 会員証発行完了画面 */
const CardRegisterComplete: React.FC = () => {
  PageTitle('会員証新規発行');

  const [searchParams] = useSearchParams();

  const isFail = searchParams.get('fail') === 'true';

  return (
    <FullHeightLayout white>
      {isFail ? <VDError /> : <RegisterSuccess />}
    </FullHeightLayout>
  );
};
export default CardRegisterComplete;
