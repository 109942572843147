import liff from '@line/liff/dist/lib';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ONLINE_CHARGE_TRANSITION_PAGE_PATH,
  ONLINE_CHARGE_TRANSITION_PAGE_URL,
  SESSION_EXPIRED_PAGE_PATH
} from 'lib/constants';

liff
  .init({
    liffId: process.env.REACT_APP_LIFF_ID || ''
    // LIFF内ブラウザで外部のクレジットカードチャージサイトへの遷移が必要なため、他アプリで利用している withLoginOnExternalBrowser: true は利用しない
  })
  .then(() => {
    // LINE内ブラウザ向けの分岐処理（LIFFブラウザの場合、liff.init()実行時に自動でログイン処理が実行される）
    // cf. https://developers.line.biz/ja/reference/liff/#login
    if (!liff.isLoggedIn()) {
      switch (window.location.pathname) {
        // セッション切れ画面の場合、ユーザー操作でアプリを再起動してもらう必要があるので、そのままレンダリングする
        case SESSION_EXPIRED_PAGE_PATH:
          break;
        // 外部のクレジットカードチャージサイトへの移動確認画面の場合、ログイン後に移動確認画面へリダイレクトする
        // （リダイレクト前に後続のレンダリングが実行されないように return する）
        case ONLINE_CHARGE_TRANSITION_PAGE_PATH:
          return liff.login({ redirectUri: ONLINE_CHARGE_TRANSITION_PAGE_URL });
        // 外部のクレジットカードチャージサイトへの移動確認画面以外の場合、ログイン後にトップ画面へリダイレクトする
        // （リダイレクト前に後続のレンダリングが実行されないように return する）
        default:
          return liff.login();
      }
    }

    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch((e) => {
    console.log('Error Initializing LIFF: ', e.message);
    localStorage.clear();
    liff.login();
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
