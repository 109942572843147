import React, { useState, useEffect } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import SnackBar from 'component/SnackBar';
import FormReviewItem from 'component/FormReviewItem';
import { LinkButton } from 'component/Button';
import { UserDataType } from 'types/UserType';
import { getCurrentUser } from 'api';
import Loader from 'component/Loader';

const AccountDetail: React.FC = () => {
  const [userData, setUserData] = useState<UserDataType>();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [searchParams] = useSearchParams();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  PageTitle('会員情報');

  useEffect(() => {
    const isEdited = searchParams.get('edit');
    if (isEdited) {
      setShowSnackBar(true);
    }
    getCurrentUser().then((res) => {
      setUserData(res.data);
      setTimeout(() => setIsFinished(true), 300);
    });
  }, []);

  return (
    <FullHeightLayout white>
      {isFinished ? (
        <Row>
          <Col md={7} className="p-0 mx-auto">
            <FormReviewItem
              botBorder
              label="カード番号"
              value={userData?.card_no as string}
            />
            <FormReviewItem
              botBorder
              label="PIN番号"
              value={userData?.pin_code as string}
            />
            <FormReviewItem
              botBorder
              label="お名前"
              value={`${userData?.last_name} ${userData?.first_name}`}
            />
            <FormReviewItem
              botBorder
              label="お名前 (カナ)"
              value={`${userData?.last_name_kana} ${userData?.first_name_kana}`}
            />
            <FormReviewItem
              botBorder
              label="電話番号"
              value={userData?.tel as string}
            />
            <FormReviewItem
              botBorder
              label="郵便番号"
              value={userData?.postal_code as string}
            />
            <FormReviewItem
              botBorder
              label="住所"
              value={`${userData?.address_1}${userData?.address_2}`}
            />
            <Stack className="px-3 mt-4">
              <LinkButton link="edit" text="編集" replace={true} />
            </Stack>
            <SnackBar message="会員情報編集しました" show={showSnackBar} />
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </FullHeightLayout>
  );
};
export default AccountDetail;
