export const createScriptElement = (scriptText: string, isUrl = false) => {
  const script = document.createElement('script');
  if (isUrl) {
    script.src = scriptText;
    script.async = true;
  } else {
    script.text = scriptText;
  }
  return script;
};

export const appendScripts = (
  element: HTMLElement,
  scripts: HTMLScriptElement[]
) => {
  scripts.forEach((script) => element.appendChild(script));
};

export const removeScripts = (
  element: HTMLElement,
  scripts: HTMLScriptElement[]
) => {
  scripts.forEach((script) => {
    if (element.contains(script)) {
      element.removeChild(script);
    }
  });
};
