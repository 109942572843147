import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import BrandSelect from 'component/BrandSelect';
import NewsItem from 'component/NewsItem';

import { getNewsList } from 'api';
import { NewsType } from 'types/NewsType';
import Loader from 'component/Loader';

const NewsList: React.FC = () => {
  PageTitle('お知らせ');

  const [newsList, setNewsList] = useState<Array<NewsType>>([]);
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsFinished(false);
    const target = e.target as HTMLSelectElement;
    setSelectedBrand(parseInt(target.value));
  };

  useEffect(() => {
    getNewsList(selectedBrand).then((res) => {
      setNewsList(res.data.notifications);
      setTimeout(() => setIsFinished(true), 300);
    });
  }, [selectedBrand]);

  return (
    <MainLayout footer>
      <Row>
        <Col md={7} className="mx-auto">
          <BrandSelect half onChange={handleChange} />
          {isFinished ? (
            <>
              {(newsList?.length || 0) <= 0 && (
                <p className="text--sm mb-0">お知らせがありません。</p>
              )}
              {newsList.map((news, ind) => {
                return (
                  <NewsItem
                    id={news.id}
                    title={news.title}
                    date={news.published_at}
                    image={news.image_file_name}
                    key={`news-${ind}`}
                    isUnread={news.is_unread}
                  />
                );
              })}
            </>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </MainLayout>
  );
};
export default NewsList;
