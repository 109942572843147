import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import FullBanner from 'component/FullBanner';
import StringToJsx from 'component/StringToJsx';
import Loader from 'component/Loader';

import { getNewsDetails, postNotifications } from 'api';
import { NewsType } from 'types/NewsType';
import { formatDate, openWindow } from 'lib';
import { useStateMachine } from 'little-state-machine';
import { clearNotificationsUnreadCountUpdatedAt } from 'lib/stateActions';

const NewsDetail: React.FC = () => {
  PageTitle('お知らせ');

  const { id } = useParams();

  const [news, setNews] = useState<NewsType>();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { actions } = useStateMachine({
    clearNotificationsUnreadCountUpdatedAt
  });

  useEffect(() => {
    getNewsDetails(parseInt(id as string)).then((res) => {
      setNews(res.data);
      setTimeout(() => setIsFinished(true), 300);

      // 未読であれば既読にする
      if (!res.data.is_unread) return;
      postNotifications(res.data.id).then(() =>
        // 未読のお知らせ数 の更新時刻 をクリアする
        actions.clearNotificationsUnreadCountUpdatedAt()
      );
    });
  }, []);

  return (
    <FullHeightLayout className="pt-0" white>
      {isFinished ? (
        <Row>
          <Col md={7} className="mx-auto">
            <FullBanner img={news?.image_file_name as string} />
            <div className="my-4">
              <h4 className="text--lg text--secondary">{news?.title}</h4>
              <p className="text--secondary-light">
                {formatDate(news?.published_at as string, false, true)}
              </p>
            </div>
            <div className="mb-4">
              <StringToJsx text={news?.description as string} />
            </div>
            <p className="mb-2 text--secondary-light">対象店舗:</p>
            {news?.stores?.map((store, ind) => {
              return (
                <p
                  className="mb-2 text--secondary-light text--sm"
                  key={`store-${ind}`}
                >
                  {store}
                </p>
              );
            })}
            {news?.external_links?.map((link, index) => (
              <div className="text-center my-5" key={index}>
                {link.url && link.label && (
                  <a
                    className="btn btn--full btn--primary py-3 w-75 btn--rounded"
                    href="#"
                    onClick={() => openWindow(link.url)}
                  >
                    {link.label}
                  </a>
                )}
              </div>
            ))}
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
    </FullHeightLayout>
  );
};
export default NewsDetail;
