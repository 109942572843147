import React, { useEffect, useRef } from 'react';
import {
  createScriptElement,
  appendScripts,
  removeScripts
} from 'lib/scriptHandler';

interface AdBannerType {
  fluctGroupId: string;
  fluctUnitName: string;
  fluctUnitId: string;
}

const AdBanner: React.FC<AdBannerType> = ({
  fluctGroupId,
  fluctUnitName,
  fluctUnitId
}) => {
  const adDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adLoaderScript = createScriptElement(
      'https://pdn.adingo.jp/p.js',
      true
    );
    const adDisplayConfigScript = createScriptElement(`
        var fluctAdScript = fluctAdScript || {};
        fluctAdScript.cmd = fluctAdScript.cmd || [];
        fluctAdScript.cmd.push(function (cmd) {
        cmd.setGamTargetingMap('%%PATTERN:TARGETINGMAP%%');
        cmd.requestAdByGroup("${fluctGroupId}")
            .display(".${fluctUnitName}", "${fluctUnitId}");
        });
    `);

    if (adDiv.current) {
      appendScripts(adDiv.current, [adLoaderScript, adDisplayConfigScript]);
    }

    return () => {
      if (adDiv.current) {
        removeScripts(adDiv.current, [adLoaderScript, adDisplayConfigScript]);
      }
    };
  }, []);

  return (
    <>
      <link rel="preload" as="script" href="https://pdn.adingo.jp/p.js" />
      <link
        rel="preconnect"
        href="https://sh.adingo.jp"
        crossOrigin="use-credentials"
      />
      <div className={fluctUnitName}></div>
      <div ref={adDiv}></div>
    </>
  );
};

export default AdBanner;
