import React, { useEffect } from 'react';
import { Badge, Stack } from 'react-bootstrap';
import { Link, useLocation, matchPath } from 'react-router-dom';
import HomeOn from 'assets/image/icons/home_on.svg';
import HomeOff from 'assets/image/icons/home_off.svg';
import CouponOn from 'assets/image/icons/coupon_on.svg';
import CouponOff from 'assets/image/icons/coupon_off.svg';
import StoreOn from 'assets/image/icons/store_on.svg';
import StoreOff from 'assets/image/icons/store_off.svg';
import NewsOn from 'assets/image/icons/news_on.svg';
import NewsOff from 'assets/image/icons/news_off.svg';
import UserOn from 'assets/image/icons/user_on.svg';
import UserOff from 'assets/image/icons/user_off.svg';
import { getCurrentNotificationsUnread } from 'api';
import { useStateMachine } from 'little-state-machine';
import { updateNotificationsUnreadCount } from 'lib/stateActions';
import dayjs from 'dayjs';

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  const footer = {
    home: {
      class: '',
      icon: HomeOff
    },
    coupon: {
      class: '',
      icon: CouponOff
    },
    store: {
      class: '',
      icon: StoreOff
    },
    news: {
      class: '',
      icon: NewsOff
    },
    user: {
      class: '',
      icon: UserOff
    }
  };
  if (matchPath('/', pathname) || matchPath('/history/*', pathname)) {
    footer.home.class = 'active';
    footer.home.icon = HomeOn;
  }
  if (matchPath('/coupon/*', pathname)) {
    footer.coupon.class = 'active';
    footer.coupon.icon = CouponOn;
  }
  if (matchPath('/mystore/*', pathname)) {
    footer.store.class = 'active';
    footer.store.icon = StoreOn;
  }
  if (matchPath('/news/*', pathname)) {
    footer.news.class = 'active';
    footer.news.icon = NewsOn;
  }
  if (matchPath('/mypage/*', pathname)) {
    footer.user.class = 'active';
    footer.user.icon = UserOn;
  }

  const {
    actions,
    state: {
      notificationsUnreadCount, // 未読のお知らせ数
      notificationsUnreadCountUpdatedAt // 未読のお知らせ数 の更新時刻
    }
  } = useStateMachine({
    updateNotificationsUnreadCount
  });

  // 未読のお知らせ数取得
  useEffect(() => {
    // 更新時刻から 30秒 以内は再取得を行わない
    if (
      notificationsUnreadCountUpdatedAt &&
      dayjs().diff(dayjs(notificationsUnreadCountUpdatedAt), 'second') < 30
    )
      return;

    getCurrentNotificationsUnread().then((res) => {
      if (!res || res.status === 'failed') return;
      actions.updateNotificationsUnreadCount(res.data.unread_count);
    });
  }, [notificationsUnreadCountUpdatedAt]);

  return (
    <Stack
      as="ul"
      direction="horizontal"
      className="list-unstyled justify-content-around bg-white m-0 footer"
    >
      <li className="text-center pb-2 pt-3">
        <Link to="/" className={footer.home.class}>
          <img src={footer.home.icon} alt="" className="mb-2" />
        </Link>
      </li>
      <li className="text-center pb-2 pt-3">
        <Link to="/coupon" className={footer.coupon.class}>
          <img src={footer.coupon.icon} alt="" className="mb-2" />
        </Link>
      </li>
      <li className="text-center pb-2 pt-3">
        <Link to="/mystore" className={footer.store.class}>
          <img src={footer.store.icon} alt="" className="mb-2" />
        </Link>
      </li>
      <li className="text-center pb-2 pt-3">
        <Link
          to="/news"
          className={`d-block position-relative ${footer.news.class}`}
        >
          <img src={footer.news.icon} alt="" className="mb-2" />
          {/* 未読バッジ */}
          {notificationsUnreadCount > 0 && (
            <Badge
              pill
              bg="danger"
              className="position-absolute top-0 end-0 d-block p-1"
            />
          )}
        </Link>
      </li>
      <li className="text-center pb-2 pt-3">
        <Link to="/mypage" className={footer.user.class}>
          <img src={footer.user.icon} alt="" className="mb-2" />
        </Link>
      </li>
    </Stack>
  );
};
export default Footer;
