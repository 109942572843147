import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Row, Col, Image } from 'react-bootstrap';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import FullBanner from 'component/FullBanner';
import { LinkButton, LoadingButton } from 'component/Button';
import { BottomModal, CenterModal } from 'component/Modals';
import StringToJsx from 'component/StringToJsx';
import Loader from 'component/Loader';
import Barcode from 'component/Barcode';

import { getCurrentUser, getCouponDetail, issueCoupon } from 'api';
import { CouponType } from 'types/CouponType';
import { checkBetweenDates, setCountTimer } from 'lib';

import CardBg from 'assets/image/card_bg.png';

interface CouponModalType {
  status: boolean;
  text: string;
  limit: number | null;
  isProcessing?: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  handleSpendCoupon: VoidFunction;
}

interface MembershipModalType {
  status: boolean;
  handleClose: React.MouseEventHandler<HTMLButtonElement>;
  cardNumber?: string;
}

const CouponModal: React.FC<CouponModalType> = ({
  status,
  text,
  limit,
  isProcessing = false,
  handleClose,
  handleSpendCoupon
}) => {
  return (
    <CenterModal status={status}>
      <Stack direction="horizontal" className="mb-3 justify-content-between">
        <p className="mb-0 text--lg text--secondary">注意事項</p>
        <button className="btn-close" onClick={handleClose}></button>
      </Stack>
      <div className="mb-4">
        <StringToJsx text={text} />
      </div>
      {isProcessing ? (
        <Stack>
          <LoadingButton />
        </Stack>
      ) : (
        <LinkButton
          link="#"
          text={`クーポンを発行する${limit ? `（残り${limit}回）` : ''}`}
          onClick={handleSpendCoupon}
        />
      )}
    </CenterModal>
  );
};

const MembershipModal: React.FC<MembershipModalType> = ({
  status,
  handleClose,
  cardNumber
}) => {
  return (
    <BottomModal status={status}>
      <Stack direction="horizontal" className="mb-3 justify-content-between">
        <p className="mb-0 text--lg text--secondary">会員バーコード</p>
        <button className="btn-close" onClick={handleClose}></button>
      </Stack>
      <div className="bg-transparent border-0 section-cont--rounded-2 position-relative">
        <Image src={CardBg} className="mw-100" />
        <div className="text-center position-absolute card__barcode">
          <Barcode value={cardNumber ?? ''} label={cardNumber} height={60} />
        </div>
      </div>
    </BottomModal>
  );
};

const Timer: React.FC<{ coupon?: CouponType }> = ({ coupon }) => {
  const [isIssueFinished, setIsIssueFinished] = useState<boolean>(false);
  const [timer, setTimer] = useState<{
    hour: number;
    minute: number;
    second: number;
  }>();

  useEffect(() => {
    if (coupon) {
      let duration = coupon.left_time || coupon.lifetime;
      const counter = setInterval(() => {
        if (duration < 0) {
          clearInterval(counter);
          location.reload();
        } else {
          const timer = setCountTimer(duration);
          setTimer({
            hour: timer[0],
            minute: timer[1],
            second: timer[2]
          });
          duration = --duration;
        }
        setIsIssueFinished(true);
      }, 1000);
    }
  }, []);

  return (
    <>
      {isIssueFinished ? (
        <Stack
          direction="vertical"
          className="flex-grow-1 border rounded-sm bg-white align-items-center justify-content-evenly py-4 px-1"
        >
          <div className="text-center">
            {timer && (
              <>
                <h5 className="py-4">{coupon?.name}</h5>
                <div className="text-center">
                  <span className="d-block text--secondary">有効時間</span>
                  <span className="d-block fs-3">
                    {(timer?.hour as number) > 0 &&
                      `${timer?.hour.toString().padStart(2, '0')}時間 `}
                    {(timer?.minute as number) > 0 &&
                      `${timer?.minute.toString().padStart(2, '0')}分 `}
                    {(timer?.second as number) > 0 &&
                      `${timer?.second.toString().padStart(2, '0')}秒`}
                    {Object.values(timer).every((item) => item === 0) && (
                      <p className="text-danger mb-0">有効期間切れ</p>
                    )}
                  </span>
                </div>
                <Barcode
                  value={coupon?.code as string}
                  label={coupon?.code as string}
                  height={80}
                  showLabel={true}
                />
                <p className="text-center pt-3">
                  お店のスタッフに提示して
                  <br />
                  バーコードを読み取ってください
                </p>
              </>
            )}
          </div>
        </Stack>
      ) : (
        <Loader />
      )}
    </>
  );
};

const CouponDetail: React.FC = () => {
  PageTitle('クーポン');

  const { id } = useParams();
  const [coupon, setCoupon] = useState<CouponType>();
  const [cardNumber, setCardNumber] = useState<string>();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [showCouponBarcode, setShowCouponBarcode] = useState(false);
  const [showCouponDetail, setShowCouponDetail] = useState(true);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showIssueButton, setShowIssueButton] = useState(true);
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [isCouponUnlimited, setisCouponUnlimited] = useState(false);
  const [isCouponUsable, setisCouponUsable] = useState(true);
  const [isProcessing, setIsProccessing] = useState(false);

  /** クーポン詳細からクーポンバーコードに切り替える **/
  const displayIssuedCoupon = () => {
    setShowIssueButton(false);
    setShowCouponDetail(false);
    setShowCouponBarcode(true);
    setShowCouponModal(false);
    setShowCouponDetail(true);
  };

  /** クーポン発行 **/
  const handleSpendCoupon = () => {
    setIsProccessing(true);
    issueCoupon(parseInt(id as string))
      .then(() => {
        displayIssuedCoupon();
      })
      .finally(() => setIsProccessing(false));
  };

  /** クーポンモーダル **/
  const handleShowCouponModal = () => setShowCouponModal(true);
  const handleCloseCouponModal = () => setShowCouponModal(false);

  /** 会員バーコードモーダル **/
  const handleShowMembershipModal = () => setShowMembershipModal(true);
  const handleCloseMembershipModal = () => setShowMembershipModal(false);

  useEffect(() => {
    const currUser = getCurrentUser().then((res) => {
      setCardNumber(res.data.card_no);
    });

    const coupon = getCouponDetail(parseInt(id as string)).then((res) => {
      setCoupon(res.data);

      /** クーポン利用可能 **/
      const isActive = checkBetweenDates(
        new Date().toString(),
        res.data.start_at as string,
        res.data.end_at as string
      );

      /** クーポン利用回数 **/
      if (res.data.max_use_times === 0) {
        setisCouponUnlimited(true);

        if (!isActive) {
          setisCouponUsable(false);
        }
      } else {
        if (!isActive || res.data.left_use_times <= 0) {
          setisCouponUsable(false);
        }
      }

      /** クーポン利用中 **/
      if (res.data.left_time) {
        displayIssuedCoupon();
      }
    });

    Promise.allSettled([currUser, coupon]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, []);

  return (
    <>
      {isFinished ? (
        <>
          {showCouponBarcode && (
            <FullHeightLayout>
              <Stack direction="vertical" gap={3}>
                <Timer coupon={coupon} />
                <LinkButton
                  type="primary-outline"
                  link="#"
                  text="会員バーコード"
                  onClick={handleShowMembershipModal}
                />
              </Stack>
            </FullHeightLayout>
          )}
          {showCouponDetail && (
            <FullHeightLayout className="pt-0" white>
              <Row>
                <Col md={7} className="mx-auto">
                  <FullBanner img={coupon?.image_file_name as string} />
                  <div className="my-4">
                    <h4 className="mb-4 text--lg text--secondary">
                      {coupon?.name}
                    </h4>
                    {showIssueButton && (
                      <>
                        {isCouponUnlimited &&
                          (isCouponUsable ? (
                            <LinkButton
                              link="#"
                              text="クーポンを利用する"
                              onClick={handleShowCouponModal}
                            />
                          ) : (
                            <LinkButton
                              link="#"
                              disabled
                              text="クーポンを利用する"
                              onClick={handleShowCouponModal}
                            />
                          ))}
                        {!isCouponUnlimited &&
                          (isCouponUsable ? (
                            <LinkButton
                              link="#"
                              text={`クーポンを利用する（残り${coupon?.left_use_times}回）`}
                              onClick={handleShowCouponModal}
                            />
                          ) : (
                            <LinkButton
                              link="#"
                              disabled
                              text={`クーポンを利用する（残り${coupon?.left_use_times}回）`}
                              onClick={handleShowCouponModal}
                            />
                          ))}
                      </>
                    )}
                  </div>
                  <div className="mb-4">
                    <StringToJsx text={coupon?.description as string} />
                  </div>
                  <p className="mb-2 text--secondary-light">利用可能店舗:</p>
                  {coupon?.stores?.map((store, ind) => {
                    return (
                      <p
                        className="mb-2 text--secondary-light text--sm"
                        key={`store-${ind}`}
                      >
                        {store}
                      </p>
                    );
                  })}
                </Col>
              </Row>
            </FullHeightLayout>
          )}
          <CouponModal
            status={showCouponModal}
            text={coupon?.caution as string}
            limit={
              isCouponUnlimited ? null : (coupon?.left_use_times as number)
            }
            isProcessing={isProcessing}
            handleClose={handleCloseCouponModal}
            handleSpendCoupon={handleSpendCoupon}
          />
          <MembershipModal
            status={showMembershipModal}
            handleClose={handleCloseMembershipModal}
            cardNumber={cardNumber ?? ''}
          />
        </>
      ) : (
        <FullHeightLayout white>
          <Loader />
        </FullHeightLayout>
      )}
    </>
  );
};
export default CouponDetail;
