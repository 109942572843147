import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, Row, Col } from 'react-bootstrap';
import { useStateMachine } from 'little-state-machine';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';

import { clearAction } from 'lib/stateActions';

import CardImage from 'assets/image/card_img2.png';

interface LocationStateType {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

/**
 * マキヤポイントカード（白カード・プラスチックカード）所有確認画面
 * - 初回起動画面で「お持ちでない方」クリック後、表示される
 */
const CardRegister: React.FC = () => {
  PageTitle('会員証新規発行');

  const { actions, state } = useStateMachine({ clearAction });
  const location = useLocation();

  useEffect(() => {
    actions.clearAction({ ...state });
  }, []);

  return (
    <FullHeightLayout center white>
      <Row>
        <Col md={7} className="mx-auto">
          <Stack className="text-center" gap={4}>
            <h3 className="text--lg">マキヤポイントカードはお持ちですか？</h3>
            <img src={CardImage} alt="" className="w-100 mb-3" />
            <LinkButton
              link="/register/card-confirm"
              type="secondary-outline"
              text="はい、持ってます"
              state={{
                ...(location.state as LocationStateType)
              }}
            />
            <LinkButton
              link="/register/input?has-already-card=false"
              type="secondary-outline"
              text="いいえ、持ってません"
              state={{
                ...(location.state as LocationStateType)
              }}
            />
          </Stack>
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default CardRegister;
