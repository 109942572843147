import React from 'react';
import { Stack, Row, Col } from 'react-bootstrap';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';

/**
 * 会員証発行（アプリ移行）の注意事項確認画面
 * - マキヤポイントカード保有確認画面で「はい、持ってます」クリック後に表示される
 */
const CardRegisterCardConfirm: React.FC = () => {
  PageTitle('会員証新規発行');

  return (
    <FullHeightLayout white>
      <Row>
        <Col md={7} className="mx-auto">
          <Stack gap={4}>
            <h3 className="text-center text--lg my-4">注意事項</h3>
            <ul className="text-start ps-3">
              <li className="mb-3 text--md text--primary fw-bold">
                お待ちのポイントは
                <span className="text--md text--primary text-decoration-underline">
                  ご登録の翌日に
                </span>
                アプリへ移動されます。
              </li>
              <li className="mb-3 text--md text--primary fw-bold">
                移行後、お持ちのプラスチックカードは使用不能になります。
              </li>
              <li className="mb-3 text--sm">
                アプリでは新しいカード番号が採番されます。
              </li>
              <li className="mb-3 text--sm">
                アプリ移行後にマキヤプリカのプラスチックカードをご希望の場合、カード発行手数料の100円（税込）が必要になります。
                <br />
                プラスチックカードもお持ちになりたいお客様は、店舗にてマキヤプリカへ切替手続き後にアプリへの登録をお願いいたします。
              </li>
              <li className="mb-3 text--sm">
                一部店舗のリサイクルステーションで貯まるリサイクルポイントをお持ちの場合、ご登録の翌月中旬にアプリへ移行されます。
              </li>
              <li className="mb-3 text--sm">
                シニアデー等のお客様情報によって特典のあるキャンペーンは、ご登録日の翌日から有効になります。
              </li>
            </ul>
          </Stack>
          <div className="buttom-fix mb-3">
            <Stack className="text-center" gap={4}>
              <LinkButton
                link="/register/input?has-already-card=true"
                replace={true}
                type="primary-outline"
                text="確認しました"
              />
              <LinkButton
                link="/register"
                type="secondary-link"
                replace={true}
                text="戻る"
              />
            </Stack>
          </div>
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default CardRegisterCardConfirm;
