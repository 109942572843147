import React, { useRef } from 'react';
import MainLayout from 'layout/MainLayout';
import {
  API_SUCCEEDED,
  LIFF_URL,
  ONLINE_CHARGE_CARD_NO_PREFIX,
  ONLINE_CHARGE_URL
} from 'lib/constants';
import { useForm } from 'react-hook-form';
import { OnlineChargeLoginFormValues } from 'types/UserType';
import Loader from 'component/Loader';
import { getCurrentUser } from 'api';
import { useEffectOnce } from 'react-use';

/**
 * 外部のクレジットカードチャージサイトへの移動画面
 * - ホーム画面から直接外部のクレジットカードチャージサイトへ遷移すると表示される警告メッセージの対策用の画面
 * - ミニアプリではなく、LINEブラウザ上で表示される
 * cf. https://vg-ceg.backlog.com/view/DIGICLUE_LINEMINIAPP-4155#comment-337426438
 */
const OnlineChargeTransition: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const { register, setValue } = useForm<OnlineChargeLoginFormValues>({
    defaultValues: {
      bk_url: LIFF_URL
    }
  });

  useEffectOnce(() => {
    (async () => {
      const res = await getCurrentUser();
      if (res.status !== API_SUCCEEDED) return;

      setValue('card_no', `${ONLINE_CHARGE_CARD_NO_PREFIX}${res.data.card_no}`);
      setValue('pin_no', res.data.pin_code);

      if (!formRef.current) return;
      formRef.current.submit();
    })();
  });

  return (
    <MainLayout>
      <Loader />

      {/* 外部サイト自動ログインのための情報を POST で送信する必要があるため、form を利用 */}
      <form ref={formRef} action={ONLINE_CHARGE_URL} method="post">
        <input type="hidden" {...register('card_no')} />
        <input type="hidden" {...register('pin_no')} />
        {/* 外部サイトのチャージ完了画面、また、エラー画面に配置される「戻るボタン」に設定する URL
          https://www.notion.so/digiclue/1Q-b9deee1b4b784a1081190441e131e658?pvs=4#dea9cd6046154d458905de3bde5e28fa */}
        <input type="hidden" {...register('bk_url')} />
      </form>
    </MainLayout>
  );
};

export default OnlineChargeTransition;
