import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Stack } from 'react-bootstrap';

import { checkBetweenDates, formatDate } from 'lib';

interface CouponItemType {
  id: string;
  image: string;
  title: string;
  dateStart: string;
  dateEnd: string;
}

const CouponItem: React.FC<CouponItemType> = ({
  id,
  image,
  title,
  dateStart,
  dateEnd
}) => {
  const isActive = checkBetweenDates(new Date().toString(), dateStart, dateEnd);

  let classList = 'p-2 section-cont section-cont--rounded-1 coupon-item';
  !isActive && (classList += ' coupon-item--inactive');

  return (
    <RouterLink to={`/coupon/${id}`}>
      <Stack direction="horizontal" className={classList}>
        <img src={image} alt="" className="me-3" />
        <Stack>
          <span className="text--md coupon-item__title">{title}</span>
          <span className="text--secondary-light coupon-item__date">
            {`${formatDate(dateStart, false, true)} ~ ${formatDate(
              dateEnd,
              false,
              true
            )}`}
          </span>
        </Stack>
      </Stack>
    </RouterLink>
  );
};
export default CouponItem;
