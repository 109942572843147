import React, { useEffect, useState } from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';
import ErrorModal from 'component/ErrorModal';

import { API_SUCCEEDED } from 'lib/constants';
import { errorCodes } from 'lib/errorCodes';
import { getCurrentUser, reissueCard } from 'api';
import AlertMessage from 'component/AlertMessage';

const CardReissueConfirm: React.FC = () => {
  PageTitle('新規カード発行');

  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState<string>();
  const [errCode, setErrCode] = useState<string>();
  const [showError, setShowError] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const handleClose = () => setShowError(false);

  const handleClick = () => {
    reissueCard({
      birthday: null,
      card_no: null,
      pin_code: null
    })
      .then((res) => {
        if (res.status === API_SUCCEEDED) {
          setTimeout(() => navigate('/'), 300);
        } else {
          /** エラーコードを参照して、エラーメッセージを設定する */
          const code = res.error_code as keyof typeof errorCodes;
          const formError = ['014', '016'];

          if (formError.includes(code)) {
            /** フォームのエラメッセージとして出す */
            setErrMsg(errorCodes[code].desc);
            setErrCode(code);
          } else {
            /** 失敗画面に移動 */
            setTimeout(() => navigate(`/complete?fail=true&code=${code}`), 300);
          }
        }
      })
      .catch(() => setShowError(true));
  };

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) setIsDone(true);
    });
  }, []);

  return (
    <FullHeightLayout white>
      <Row>
        <Col md={7} className="mx-auto">
          {isDone && (
            <>
              {errMsg && (
                <AlertMessage type="error" message={errMsg} code={errCode} />
              )}
              <Stack gap={4}>
                <h3 className="text-center text--lg my-4">
                  お持ちのカードを使用不能とし、
                  <br />
                  アプリ上で新しいデジタルカードを発行してもよろしいですか？
                </h3>
                <Stack className="px-3">
                  <h5 className="mb-3">注意事項</h5>
                  <ul className="text-start">
                    <li className="mb-3 text--sm">
                      現在お持ちのカードがデジタルカードの場合は、再発行を行うことができません。
                    </li>
                    <li className="mb-3 text--sm">
                      再発行完了後、すぐにアプリ上の新しいデジタルカードへマキヤプリカ残高が移行されます。
                    </li>
                    <li className="mb-3 text--sm">
                      再発行日の夜間にアプリ上の新しいデジタルカードへポイントが移行されます。
                    </li>
                    <li className="mb-3 text--sm">
                      アプリ上でデジタルカード発行後にマキヤプリカのプラスチックカードをご希望の場合、カード発行手数料の100円が必要となります。
                      プラスチックカードもお持ちになりたいお客様は、店舗にてマキヤプリカ再発行手続き後にアプリの「カード変更→別のカードを連携する」を行ってください。
                    </li>
                    <li className="mb-3 text--sm">
                      一部店舗のリサイクルステーションで貯まるリサイクルポイントがお持ちのカードに入っている場合、アプリご登録から１ヶ月程でアプリ上のデジタルカードへリサイクルポイントが移行されます。
                    </li>
                  </ul>
                </Stack>
              </Stack>
              <div className="buttom-fix mb-3">
                <Stack className="text-center" gap={4}>
                  <LinkButton
                    link="#"
                    replace={true}
                    type="primary-outline"
                    text="再発行"
                    onClick={handleClick}
                  />
                  <LinkButton
                    link="/mypage/reissue"
                    type="secondary-link"
                    replace={true}
                    text="戻る"
                  />
                </Stack>
              </div>
              <ErrorModal
                status={showError}
                message="こちらの機能は、23:00から7:00までご利用になれません。"
                handleClose={handleClose}
              />
            </>
          )}
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default CardReissueConfirm;
