import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from 'react-bootstrap';

import { openWindow } from 'lib';
import Loader from './Loader';

interface ButtonType {
  text: string | (string | JSX.Element)[];
  disabled?: boolean | true;
  type?:
    | 'primary'
    | 'primary-light'
    | 'primary-outline'
    | 'primary-link'
    | 'secondary-light'
    | 'secondary-outline'
    | 'secondary-link';
  className?: string;
}
interface LinkButtonType extends ExtendedLinkProps {
  link: string;
  external?: true;
}

type ExtendedLinkProps = ButtonType & Omit<LinkProps, 'to' | 'className'>;
type SubmitButtonType = ButtonType &
  Omit<ButtonProps, 'disabled' | 'className'>;

export const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonType>(
  (
    { link, text, disabled, external, type = 'primary', className, ...props },
    ref
  ) => {
    let classList = 'd-flex justify-content-center btn py-3 btn--custom';
    classList += ` btn--${type} `;
    disabled && (classList += ' disabled');
    className && (classList += ` ${className}`);

    if (!external) {
      return (
        <Link to={link} className={classList} {...props} ref={ref}>
          {text}
        </Link>
      );
    } else {
      return (
        <a
          href="#"
          className={classList}
          {...props}
          ref={ref}
          onClick={() => openWindow(link)}
        >
          {text}
        </a>
      );
    }
  }
);
LinkButton.displayName = 'LinkButton';

export const SubmitButton = React.forwardRef<
  HTMLButtonElement,
  SubmitButtonType
>(({ text, disabled, type = 'primary', className, ...props }, ref) => {
  let classList = `btn py-3 btn--custom ${'btn--' + type}`;
  disabled && (classList += ' disabled');
  className && (classList += ` ${className}`);

  return (
    <Button type="submit" className={classList} {...props} ref={ref}>
      {text}
    </Button>
  );
});
SubmitButton.displayName = 'SubmitButton';

export const LoadingButton: React.FC = () => {
  return (
    <Button className="btn py-3 disabled">
      <Loader />
    </Button>
  );
};
