import { TransactionDataType } from 'types/CardHistoryType';

/**
 * @param transClass - (string) 取引種類の番号
 * @returns boolean
 */
export const checkTransaction = (transClass: string) => {
  // 残高増 - 取引種類
  const accumulate = [
    '4002',
    '4004',
    '4006',
    '4012',
    '4022',
    '4026',
    '4801',
    '4802',
    '4804',
    '4806',
    '4809',
    '9804'
  ];
  // 残高減 - 取引種類
  const dissipate = [
    '4003',
    '4005',
    '4015',
    '4023',
    '4025',
    '4803',
    '4805',
    '4807',
    '9803'
  ];

  const isAdd =
    accumulate.includes(transClass) && !dissipate.includes(transClass);

  return isAdd;
};

/**
 * @param trans - (TransactionDataType) 取引情報
 * @returns number
 */
export const getTransactionAmount = (trans: TransactionDataType) => {
  let amount = 0;

  /* eslint-disable indent */
  switch (trans.req_class) {
    case '4002':
    case '4801':
      amount = trans.deposit_value || 0;
      break;
    case '4003':
    case '4803':
      amount = trans.paid_value || 0;
      break;
    case '4004':
    case '4804':
      amount = trans.sendback_value || 0;
      break;
    case '4005':
    case '4805':
      amount = trans.deposit_cancel_value || 0;
      break;
    case '4006':
    case '4806':
      amount = trans.paid_cancel_value || 0;
      break;
    case '4012':
    case '4015':
    case '4802':
      amount = trans.coupon_bonus_value || 0;
      break;
    case '4022':
    case '4025':
      amount = trans.issue_point_value || 0;
      break;
    case '4023':
    case '4026':
      amount = trans.paid_point_value || 0;
      break;
    case '4807':
      amount =
        trans.lapse_value ||
        trans.lapse_bonus_value ||
        trans.lapse_coupon_value ||
        trans.lapse_point_value ||
        0;
      break;
    case '4809':
      amount = trans.coupon_bonus_value || trans.issue_bonus_value || 0;
      break;
    case '9804':
      amount = trans.balance_total || trans.balance_point || 0;
      break;
    default:
      break;
  }

  return amount;
};
