import { useBarcode } from 'next-barcode';
import React from 'react';

interface BarcodeType {
  value: string;
  label?: string;
  height?: number;
  showLabel?: boolean;
}

const Barcode: React.FC<BarcodeType> = ({
  value,
  label,
  height,
  showLabel
}) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      format: 'EAN13',
      flat: true,
      displayValue: showLabel,
      height: height || 50,
      text: label,
      fontSize: 12,
      font: 'Noto Sans JP, monospace'
    }
  });

  return <img ref={inputRef} className="mw-100" alt="" />;
};
export default Barcode;
