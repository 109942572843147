import React, { ComponentPropsWithoutRef } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface TextInputType
  extends FormControlProps,
    Pick<ComponentPropsWithoutRef<'input'>, 'maxLength'> {
  id?: string;
  label?: string;
  type?: 'text' | 'email' | 'number' | 'tel' | 'password';
  placeholder?: string;
  caption?: string;
  className?: string;
  register?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputType>(
  (
    {
      id,
      label,
      type = 'text',
      placeholder,
      caption,
      className,
      register,
      ...props
    },
    ref
  ) => {
    let classList = 'mb-3';
    className && (classList += ` ${className}`);

    return (
      <Form.Group className={classList} controlId={id}>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          type={type}
          placeholder={placeholder}
          autoComplete=""
          {...register}
          {...props}
          ref={ref}
        />
        {caption && (
          <Form.Text className="text--sm text--secondary-light">
            {caption}
          </Form.Text>
        )}
      </Form.Group>
    );
  }
);
TextInput.displayName = 'TextInput';
export default TextInput;
