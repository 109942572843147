import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as yup from 'yup';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import TextInput from 'component/TextInput';
import AlertMessage from 'component/AlertMessage';
import { useEffectOnce } from 'react-use';
import { getCurrentUser, postSmartReceiptLink } from 'api';
import Loader from 'component/Loader';

type Inputs = {
  smartReceiptId: string;
  smartReceiptPassword: string;
};

const schema = yup.object().shape({
  smartReceiptId: yup
    .string()
    .required('メールアドレスまたは携帯電話番号を入力してください。')
    .matches(
      /^[!-~]+$/,
      'メールアドレスまたは携帯電話番号を半角で入力してください。'
    ),
  smartReceiptPassword: yup.string().required('パスワードを入力してください。')
});

const SmartReceiptConnect: React.FC = () => {
  PageTitle('登録');

  const navigate = useNavigate();
  const [cardNumber, setCardNumber] = useState('');

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting }
  } = useForm<Inputs>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit = handleSubmit(
    async ({ smartReceiptId, smartReceiptPassword }) => {
      const res = await postSmartReceiptLink(
        smartReceiptId,
        smartReceiptPassword
      );
      if (!res) return;
      // 失敗時
      if (res.status === 'failed') {
        setError('root', { message: res.errors.join('\n') });
        return;
      }
      // 成功時
      navigate('/?smartConnect=true');
    }
  );

  useEffectOnce(() => {
    // カード番号取得
    getCurrentUser().then((res) => setCardNumber(res.data.card_no));
  });

  // カード番号の取得完了までローディングを表示
  if (!cardNumber)
    return (
      <div className="mt-4">
        <Loader />
      </div>
    );

  return (
    <>
      {/* バリデーションエラー */}
      {errors.smartReceiptId?.message && (
        <AlertMessage type="error" message={errors.smartReceiptId.message} />
      )}
      {errors.smartReceiptPassword?.message && (
        <AlertMessage
          type="error"
          message={errors.smartReceiptPassword.message}
        />
      )}
      {/* API レスポンスのエラー */}
      {errors.root?.message &&
        errors.root.message
          .split('\n')
          .map((message, index) => (
            <AlertMessage key={index} type="error" message={message} />
          ))}
      <FullHeightLayout white>
        <Row className="justify-content-center">
          <Col md={7}>
            <Form onSubmit={onSubmit} className="px-3 d-grid">
              <p className="mb-4">
                お客様のマキヤプリカカード番号をスマートレシートに連携します。
                <br />
                スマートレシートログインするための登録情報を入力してください。
              </p>
              <TextInput
                type="number"
                id="cardNumber"
                label="マキヤプリカ カード番号"
                value={cardNumber}
                caption="※カード番号は変更できません。"
                className="mb-5"
                readOnly
              />
              <TextInput
                id="smartReceiptId"
                label="メールアドレス または 携帯電話番号"
                placeholder="xxx@yyyy.zzz"
                caption="※スマートレシートに登録している情報を入れてください。"
                className="mb-4"
                maxLength={254}
                {...register('smartReceiptId')}
              />
              <TextInput
                type="password"
                id="smartReceiptPassword"
                label="パスワード"
                placeholder="*********"
                caption="※スマートレシートに登録している情報を入れてください。"
                className="mb-4"
                maxLength={254}
                {...register('smartReceiptPassword')}
              />
              <Button
                type="submit"
                variant="-primary"
                className="btn--custom p-3"
                disabled={isSubmitting}
              >
                カード番号連携を行う
              </Button>
            </Form>
          </Col>
        </Row>
      </FullHeightLayout>
    </>
  );
};

export default SmartReceiptConnect;
