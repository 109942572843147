import React, { useEffect, useState } from 'react';
import { Stack, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import TextInput from 'component/TextInput';
import { SubmitButton } from 'component/Button';
import { StoreType, StoreInfoType } from 'types/StoreType';
import { BrandResponseType } from 'types/BrandType';
import { updateAction } from 'lib/stateActions';
import { getStoreList, getBrandList } from 'api';

import { ReactComponent as MapPin } from 'assets/image/icons/MapPin.svg';
import Loader from 'component/Loader';

type StoreCardProps = {
  storeId: string;
  storeName: string;
  zipcode: string;
  address: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

interface LocationStateType {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

const StoreCard: React.FC<StoreCardProps> = (props) => {
  const { storeId, storeName, zipcode, address, checked, onChange } = props;
  return (
    <Form.Check
      type="radio"
      className="store-card bg-white"
      id={`store-${storeId}`}
    >
      <Form.Check.Input
        type="radio"
        name="store"
        value={storeId}
        data-storename={storeName}
        data-zipcode={zipcode}
        data-address={address}
        checked={checked}
        onChange={onChange}
      />
      <Form.Check.Label>
        <Stack direction="vertical">
          <div className="inline-block">
            <MapPin />
            <span className="store-card--name">{storeName}</span>
          </div>
          <span className="store-card--address">
            〒{zipcode} {address}
          </span>
        </Stack>
      </Form.Check.Label>
    </Form.Check>
  );
};

const CardRegisterStoreChoice: React.FC = () => {
  PageTitle('会員証新規発行');

  const navigate = useNavigate();
  const location = useLocation();
  const { actions, state } = useStateMachine({ updateAction });

  const [storeList, setStoreList] = useState<Array<StoreInfoType>>([]);
  const [filteredStoreList, setFilteredStoreList] = useState<
    Array<StoreInfoType>
  >([]);
  const [brandOptions, setBrandOptions] = useState<Array<BrandResponseType>>(
    []
  );
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedStore, setSelectedStore] = useState<StoreType>();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const filterStore = ({
    term,
    storeList
  }: {
    term?: string;
    storeList: Array<StoreInfoType>;
  }) => {
    return storeList.filter((store) => {
      const keywordCond =
        store.name.includes(term as string) ||
        store.address.includes(term as string);

      return keywordCond;
    });
  };

  const handleAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filtered = value
      ? filterStore({ term: value, storeList: storeList })
      : storeList;

    setFilteredStoreList(filtered);
    setSearchTerm(e.target.value);
  };

  const handleBrandChange = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const value =
      parseInt(target.value) === selectedBrand ? undefined : target.value;
    setSelectedBrand(parseInt(value as string));
  };

  const handleStoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const store: StoreType = {
      id: parseInt(e.target.value),
      name: e.target.dataset.storename ?? '',
      zipcode: e.target.dataset.zipcode ?? '',
      address: e.target.dataset.address ?? ''
    };
    setSelectedStore(store);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    actions.updateAction({ ...state, store: selectedStore });
    navigate('/register/confirm', {
      state: { ...(location.state as LocationStateType) }
    });
  };

  useEffect(() => {
    const stores = getStoreList(selectedBrand).then((res) => {
      const filtered = filterStore({
        term: searchTerm,
        storeList: res.data.stores
      });
      setStoreList(filtered);
      setFilteredStoreList(filtered);
    });

    const brands = getBrandList().then((res) =>
      setBrandOptions(res.data.brands)
    );

    Promise.allSettled([stores, brands]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, [selectedBrand, searchTerm]);

  return (
    <FullHeightLayout className="px-0 pt-0 overflow-hidden">
      <Row>
        <Col md={7} className="mx-auto">
          <div className="bg-white text-center pt-5 pb-4">
            <h4>よく行く店舗を選択してください。</h4>
            <p>※重複選択は不可</p>
          </div>
          <Stack as="form" onSubmit={handleSubmit} className="pt-4">
            <Row>
              <Col className="mx-0">
                <div className="px-2">
                  <TextInput
                    id="area"
                    label="エリア名から検索する"
                    placeholder="例：富士市"
                    onChange={handleAreaChange}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                  />
                </div>
                <Form.Group className="mt-4 px-2" controlId="brand">
                  <Form.Label className="d-block">ブランドから選択</Form.Label>
                  <Row>
                    {brandOptions.map((brand, index) => (
                      <Col xs={4} key={`brand-${index}`}>
                        <Form.Check
                          type="radio"
                          className="brand"
                          id={brand.id}
                        >
                          <Form.Check.Input
                            type="radio"
                            name="brand"
                            value={brand.id}
                            onClick={handleBrandChange}
                          />
                          <Form.Check.Label
                            className={`brand-image ${
                              selectedBrand &&
                              selectedBrand !== parseInt(brand.id) &&
                              'unselected'
                            }`}
                          >
                            <img src={brand.image_file_name} />
                          </Form.Check.Label>
                        </Form.Check>
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
                <Form.Group className="pb-5 mb-3">
                  <Form.Label className="d-block mt-4 px-2">
                    全体{filteredStoreList.length}件
                  </Form.Label>
                  <Stack>
                    {isFinished ? (
                      <>
                        {filteredStoreList?.map((store, ind) => {
                          return (
                            <StoreCard
                              storeId={store.id.toString()}
                              storeName={store.name}
                              zipcode={store.postal_code}
                              address={store.address}
                              checked={selectedStore?.id === store.id}
                              onChange={(e) => handleStoreChange(e)}
                              key={ind}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </Stack>
                </Form.Group>
              </Col>
            </Row>
            <Row className="w-100">
              <Col className="bottom-fix mx-auto bottom-0 mb-3 position-fixed mx-auto">
                <Stack>
                  <SubmitButton
                    disabled={!selectedStore}
                    text="登録確認へ"
                    className="js-test"
                  />
                </Stack>
              </Col>
            </Row>
          </Stack>
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default CardRegisterStoreChoice;
