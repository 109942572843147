import React from 'react';

import StringToJsx from './StringToJsx';

interface AlertMessageType {
  message: string;
  code?: string;
  type: 'error';
}

const AlertMessage: React.FC<AlertMessageType> = ({ message, code, type }) => {
  let classList = 'px-3 pb-3';
  type === 'error' && (classList += ' bg--error error-message');

  return (
    <div className={classList}>
      <StringToJsx text={message} className="text--sm" />
      {code && (
        <span className="d-block text--sm mt-4">エラーコード: {code}</span>
      )}
    </div>
  );
};
export default AlertMessage;
