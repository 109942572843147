import React from 'react';
import { Stack } from 'react-bootstrap';

const SnackBar: React.FC<{ message: string; show: boolean }> = ({
  message,
  show = false
}) => {
  const alertBox = document.querySelector('.snackbar');
  if (show) {
    alertBox?.classList.replace('snackbar--fadeOut', 'snackbar--fadeIn');
    setTimeout(
      () =>
        alertBox?.classList.replace('snackbar--fadeIn', 'snackbar--fadeOut'),
      2000
    );
  }

  return (
    <div className="snackbar snackbar--fadeOut">
      <Stack>
        <span className="mb-1">{message}</span>
      </Stack>
    </div>
  );
};
export default SnackBar;
