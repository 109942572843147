import React from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

interface RadioInputType extends FormControlProps {
  id: string;
  label: string;
  value: string | number;
  className?: string;
  register?: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
}

const RadioInput = React.forwardRef<HTMLInputElement, RadioInputType>(
  ({ id, label, value, className, register, ...props }, ref) => {
    let classList = 'mb-3';
    className && (classList += ` ${className}`);

    return (
      <Form.Check
        inline={true}
        type="radio"
        name={id}
        id={`${id}-${label}`}
        value={value}
        label={label}
        ref={ref}
        {...register}
        {...props}
      />
    );
  }
);
RadioInput.displayName = 'RadioInput';
export default RadioInput;
