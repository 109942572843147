import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import GoogleAnalytics from 'lib/GoogleAnalytics';

import Top from 'page/Top';
import CardConnect from 'page/CardConnect';
import CardValidate from 'page/CardValidate';
import CardComplete from 'page/CardComplete';
import CardHistory from 'page/CardHistory';
import CouponList from 'page/CouponList';
import CouponDetail from 'page/CouponDetail';
import NewsList from 'page/NewsList';
import NewsDetail from 'page/NewsDetail';
import MyPage from 'page/MyPage';
import MyStore from 'page/MyStore';
import StoreSearch from 'page/StoreSearch';
import StoreDetail from 'page/StoreDetail';
import AccountDetail from 'page/AccountDetail';
import AccountEdit from 'page/AccountEdit';
import CardRegister from 'page/CardRegister';
import CardRegisterInput from 'page/CardRegisterInput';
import CardRegisterStoreChoice from 'page/CardRegisterStoreChoice';
import CardRegisterConfirm from 'page/CardRegisterConfirm';
import CardRegisterComplete from 'page/CardRegisterComplete';
import CardReissue from 'page/CardReissue';
import CardReissueConfirm from 'page/CardReissueConfirm';
import CardRegisterCardConfirm from 'page/CardRegisterCardConfirm';
import SmartReceiptNotice from 'page/SmartReceiptNotice';
import SmartReceiptConnect from 'page/SmartReceiptConnect';
import NotFound from 'page/404';
import SessionExpired from 'page/SessionExpired';
import ServerError from 'page/ServerError';
import DigiclueplusPointCoupons from 'page/digiclueplus/DigiclueplusPointCoupons';
import OnlineChargeTransition from 'page/OnlineChargeTransition';
import {
  ONLINE_CHARGE_TRANSITION_PAGE_PATH,
  SESSION_EXPIRED_PAGE_PATH
} from 'lib/constants';
import PrepaidChargeComplete from 'page/prepaid-charge/PrepaidChargeComplete';
import PrepaidChargeInput from 'page/prepaid-charge/PrepaidChargeInput';
import PrepaidChargeConfirm from 'page/prepaid-charge/PrepaidChargeConfirm';
import DigiclueplusCampaign from 'page/digiclueplus/DigiclueplusCampaign';

const CustomRoutes: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Top />} />
        {/* カード（マキヤプリカ）連携画面 */}
        <Route path="/connect" element={<CardConnect />} />
        {/* 本人確認画面 */}
        <Route path="/validate" element={<CardValidate />} />
        {/* カード（マキヤプリカ）連携完了画面 */}
        <Route path="/complete" element={<CardComplete />} />
        {/* マキヤポイントカード（白カード・プラスチックカード）所有確認画面 */}
        <Route path="/register" element={<CardRegister />} />
        {/* 会員証発行（アプリ移行）の注意事項確認画面 */}
        <Route
          path="/register/card-confirm"
          element={<CardRegisterCardConfirm />}
        />
        {/* 会員証発行フォーム（会員情報入力）画面 */}
        <Route path="/register/input" element={<CardRegisterInput />} />
        <Route
          path="/register/store-choice"
          element={<CardRegisterStoreChoice />}
        />
        {/* 会員証発行フォーム入力確認画面 */}
        <Route path="/register/confirm" element={<CardRegisterConfirm />} />
        {/* 会員証発行完了画面 */}
        <Route path="/register/complete" element={<CardRegisterComplete />} />
        <Route path="/history" element={<CardHistory />} />
        <Route path="/coupon" element={<CouponList />} />
        <Route path="/coupon/:id" element={<CouponDetail />} />
        <Route
          path="/coupon/digiclueplus/point-coupons"
          element={<DigiclueplusPointCoupons />}
        />
        <Route
          path="/coupon/digiclueplus/campaign"
          element={<DigiclueplusCampaign />}
        />
        <Route path="/mystore" element={<MyStore />} />
        <Route path="/news" element={<NewsList />} />
        <Route path="/news/:id" element={<NewsDetail />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/mypage/search" element={<StoreSearch />} />
        <Route path="/mypage/account" element={<AccountDetail />} />
        <Route path="/mypage/account/edit" element={<AccountEdit />} />
        {/* カード変更画面 */}
        <Route path="/mypage/reissue" element={<CardReissue />} />
        <Route
          path="/mypage/reissue/confirm"
          element={<CardReissueConfirm />}
        />
        <Route path="/store/:id" element={<StoreDetail />} />
        <Route path="/smart-receipt/notice" element={<SmartReceiptNotice />} />
        <Route
          path="/smart-receipt/connect"
          element={<SmartReceiptConnect />}
        />

        <Route
          path={ONLINE_CHARGE_TRANSITION_PAGE_PATH}
          element={<OnlineChargeTransition />}
        />

        {/* プリカチャージ ポイントチャージ入力フォーム画面 */}
        <Route path="/prepaid-charge" element={<PrepaidChargeInput />} />
        {/* プリカチャージ ポイントチャージ確認画面 */}
        <Route
          path="/prepaid-charge/confirm"
          element={<PrepaidChargeConfirm />}
        />
        {/* プリカチャージ ポイントチャージ完了画面 */}
        <Route
          path="/prepaid-charge/complete"
          element={<PrepaidChargeComplete />}
        />

        <Route path="/error/500" element={<ServerError />} />
        <Route path={SESSION_EXPIRED_PAGE_PATH} element={<SessionExpired />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <GoogleAnalytics />
    </BrowserRouter>
  );
};
export default CustomRoutes;
