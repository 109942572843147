import React, { useEffect, useState } from 'react';
import { Stack, Row, Col, Button, Image, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import Barcode from 'component/Barcode';
import Loader from 'component/Loader';
import SnackBar from 'component/SnackBar';
import AdBanner from 'component/AdBanner';
import AdPopup from 'component/AdPopup';

import { getCurrentUser, getSmartReceipt } from 'api';
import {
  EBASE_APP_URL,
  SMARTRECEIPT_LINK,
  ONLINE_CHARGE_TRANSITION_PAGE_URL,
  AD_BANNERS,
  POPUP_AD_SRC,
  POPUP_GCM_SRC
} from 'lib/constants';

import ChevronRightDarkIcon from 'assets/image/icons/chevron_right_dark.svg';
import RefreshIcon from 'assets/image/icons/refresh_black.svg';
import CardBg from 'assets/image/card_bg.png';
import { ReactComponent as CoinIcon } from 'assets/image/icons/coin.svg';
import ebaseBannerImg from 'assets/image/ebase_banner_img.png';
import { openWindow } from 'lib';
import smartReceiptOn from 'assets/image/smart_receipt_on.png';
import smartReceiptOff from 'assets/image/smart_receipt_off.png';
import { UserDataType } from 'types/UserType';
import usePrepaidBalanceTotal from 'lib/hooks/usePrepaidBalanceTotal';

const Card: React.FC<{ cardNumber: string }> = ({ cardNumber }) => {
  return (
    <Stack className="p-3 full-width card bg-transparent border-0">
      <Stack className="section-cont bg-transparent border-0 section-cont--rounded-2 position-relative">
        <Image src={CardBg} className="mw-100" />
        <div className="text-center position-absolute card__barcode">
          <Barcode value={cardNumber} label={cardNumber} showLabel={false} />
        </div>
      </Stack>
    </Stack>
  );
};

// eslint-disable-next-line react/prop-types
const SectionCard: typeof Stack = ({ children, ...props }) => {
  return (
    <Stack
      {...props}
      className="px-3 py-4 px-4 py-3 mb-3 section-cont section-cont--rounded-2 section-cont--shadow justify-content-between"
    >
      {children}
    </Stack>
  );
};

/**
 * 現在時刻の更新日時文字列を返す
 */
const getUpdateTimeText = () => {
  const currDate = new Date();
  const date =
    currDate.getFullYear() +
    '/' +
    ('0' + (currDate.getMonth() + 1)).slice(-2) +
    '/' +
    currDate.getDate();
  const time =
    currDate.getHours() +
    ':' +
    ('0' + currDate.getMinutes()).slice(-2) +
    ':' +
    ('0' + currDate.getSeconds()).slice(-2);
  return date + ' ' + time + ' 時点';
};

const CardHome: React.FC = () => {
  const navigate = useNavigate();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [userData, setUserData] = useState<UserDataType>();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [updateTime, setUpdateTime] = useState<string>(getUpdateTimeText());
  const [isSmartReceiptLinked, setIsSmartReceiptLinked] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { prepaidBalanceTotal, isLoadingGetPrepaid, executeCurrentPrepaid } =
    usePrepaidBalanceTotal(true);

  useEffect(() => {
    if (searchParams.get('smartConnect') === 'true') {
      setShowSnackBar(true);
      setSearchParams('');
    }

    const user = getCurrentUser().then((res) => {
      setUserData(res.data);
    });

    const smartReceipt = getSmartReceipt().then((res) => {
      if (!res || res.status === 'failed') return;
      setIsSmartReceiptLinked(res.data.linked);
    });

    Promise.allSettled([user, smartReceipt]).then(() =>
      setTimeout(() => setIsFinished(true), 300)
    );
  }, [updateTime]);

  return (
    <MainLayout className="home" footer>
      {isFinished ? (
        <Row>
          <Col md={7} className="mx-auto">
            <Card cardNumber={userData?.card_no || ''} />
            <SectionCard gap={4}>
              <Stack
                direction="horizontal"
                className="w-100 justify-content-between"
              >
                <span className="barcodeValidate">{updateTime}</span>
                <Button
                  className="refresh-btn btn--secondary-light text--dark"
                  onClick={() => {
                    setUpdateTime(getUpdateTimeText());
                    executeCurrentPrepaid();
                  }}
                >
                  <img className="me-1" src={RefreshIcon} alt="更新アイコン" />
                  更新
                </Button>
              </Stack>

              <Stack
                direction="horizontal"
                className="w-100 justify-content-between"
              >
                <span>
                  マキヤプリカ
                  <br />
                  残高
                </span>
                <span className="fw-bold text--big">
                  {isLoadingGetPrepaid ? (
                    <Spinner animation="border" role="status" />
                  ) : (
                    prepaidBalanceTotal?.toLocaleString() ?? '---'
                  )}
                  <span className="fw-normal fs-3">円</span>
                </span>
              </Stack>

              <Link to="/history" className="btn btn--secondary-outline py-3">
                残高内訳・履歴{' '}
                <img
                  src={ChevronRightDarkIcon}
                  className="ms-2"
                  alt="右矢印アイコン"
                />
              </Link>

              <Button
                className="btn btn--secondary-outline py-3 w-100"
                onClick={() => {
                  openWindow(ONLINE_CHARGE_TRANSITION_PAGE_URL);
                }}
              >
                クレジットカードチャージ
                <img
                  src={ChevronRightDarkIcon}
                  className="ms-2"
                  alt="右矢印アイコン"
                />
              </Button>
            </SectionCard>

            <SectionCard as="a" href="/prepaid-charge" direction="horizontal">
              <Stack gap={1}>
                <Stack direction="horizontal">
                  <span className="text--md fw-bold">保有ポイント確認</span>
                  <img
                    src={ChevronRightDarkIcon}
                    className="ms-2"
                    alt="右矢印アイコン"
                  />
                </Stack>
                <span className="text--md">
                  マキヤプリカへのチャージもこちら
                </span>
              </Stack>
              <CoinIcon className="me-2" height={31} width={33} />
            </SectionCard>

            {/* 広告バナー */}
            {AD_BANNERS.map((banner, index) => (
              <Stack
                key={index}
                direction="horizontal"
                className="justify-content-center mb-3"
              >
                <AdBanner
                  fluctGroupId={banner.fluctGroupId}
                  fluctUnitName={banner.fluctUnitName}
                  fluctUnitId={banner.fluctUnitId}
                />
              </Stack>
            ))}

            {/* 広告ポップアップ */}
            <AdPopup adSrc={POPUP_AD_SRC} gcmSrc={POPUP_GCM_SRC} />

            {/* スマートレシート */}
            <Button className="d-block w-100 p-0 mb-3 border-0">
              <Image
                {...(isSmartReceiptLinked
                  ? {
                      src: smartReceiptOn,
                      alt: 'スマートレシート連携済',
                      onClick: () => openWindow(SMARTRECEIPT_LINK)
                    }
                  : {
                      src: smartReceiptOff,
                      alt: 'スマートレシート未連携',
                      onClick: () => navigate('/smart-receipt/notice')
                    })}
                fluid
                className="w-100"
              />
            </Button>

            {/* eBASE バナー */}
            <SectionCard as="a" href={EBASE_APP_URL}>
              <Image src={ebaseBannerImg} alt="eBASE バナー画像" fluid />
            </SectionCard>
          </Col>
        </Row>
      ) : (
        <Row className="mt-4">
          <Col md={7} className="mx-auto">
            <Loader />
          </Col>
        </Row>
      )}
      {/* スマレシ連携完了用のトースト */}
      <SnackBar
        message="スマートレシートと連携しました。"
        show={showSnackBar}
      />
    </MainLayout>
  );
};
export default CardHome;
