import React from 'react';
import { Stack } from 'react-bootstrap';

interface FormReviewItemType {
  label: string;
  value: string;
  botBorder?: true;
}

const FormReviewItem: React.FC<FormReviewItemType> = ({
  label,
  value,
  botBorder = false
}) => {
  let classList = 'px-3 mb-3 form-review-item';
  botBorder && (classList += ' form-review-item--bot-border');

  return (
    <Stack className={classList}>
      <p className="text--secondary-light fw-bold">{label}</p>
      <p className="ms-3">{value}</p>
    </Stack>
  );
};
export default FormReviewItem;
