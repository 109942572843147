import React from 'react';
import { Stack } from 'react-bootstrap';

import cardSuccess from 'assets/image/card_success.svg';
import cardFail from 'assets/image/card_fail.svg';
import pointExchangeFail from 'assets/image/point_exchange_fail.svg';

export type ImageType = {
  type: 'cardSuccess' | 'cardFail' | 'pointExchangeFail';
};

type StatusImageType = {
  text: string;
} & ImageType;

const imageObject = {
  cardSuccess,
  cardFail,
  pointExchangeFail
};

const StatusImage: React.FC<StatusImageType> = ({ type, text }) => (
  <Stack className="align-items-center">
    <img src={imageObject[type]} alt="" className="mb-4" />
    <p className="text--lg">{text}</p>
  </Stack>
);
export default StatusImage;
