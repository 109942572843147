import React from 'react';

const FullBanner: React.FC<{ img: string }> = ({ img }) => {
  return (
    <div className="full-banner full-width">
      <img src={img} alt="" />
    </div>
  );
};
export default FullBanner;
