import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import VDError from './VDError';
import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import StatusImage from 'component/StatusImage';
import { LinkButton } from 'component/Button';

const CardSuccess: React.FC = () => {
  return (
    <>
      <Row className="flex-grow-1 align-items-center">
        <Col md={7} className="mx-auto">
          <StatusImage type="cardSuccess" text="会員登録が完了しました" />
        </Col>
      </Row>
      <Row className="bottom-fix mb-3">
        <Col md={7} className="mx-auto">
          <LinkButton link="/" text="ホームへ" />
        </Col>
      </Row>
    </>
  );
};

/** カード（マキヤプリカ）連携完了画面 */
const CardComplete: React.FC = () => {
  PageTitle('マキヤプリカ連携');

  const [searchParams] = useSearchParams();

  const isFail = searchParams.get('fail') === 'true';

  return (
    <FullHeightLayout white>
      {isFail ? <VDError /> : <CardSuccess />}
    </FullHeightLayout>
  );
};
export default CardComplete;
