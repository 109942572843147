import React, { useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import postal_code from 'japan-postal-code';
import { toHalfwidthKana, toAscii } from 'japanese-string-utils';

import { countByte } from 'lib';
import { getCurrentUser, updateCurrentUser } from 'api';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import FormReviewItem from 'component/FormReviewItem';
import TextInput from 'component/TextInput';
import { LoadingButton, SubmitButton } from 'component/Button';
import AlertMessage from 'component/AlertMessage';
import { UserDataType } from 'types/UserType';
import ErrorModal from 'component/ErrorModal';

type FormValuesType = {
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  gender: string;
  tel: string;
  zipcode: string;
  address1: string;
  address2: string;
};

const AccountEdit: React.FC = () => {
  PageTitle('会員情報編集');

  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserDataType>();
  const [showError, setShowError] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [isProcessing, setIsProccessing] = useState<boolean>(false);

  const validationSchema = yup.object({
    lastName: yup
      .string()
      .required('「姓」が入力されていません')
      .matches(/^[^"',<>&\s*]+$/, '「姓」にスペースと特殊文字は使用できません')
      .when('firstName', (firstName, schema) => {
        return schema.test({
          test: (lastName: string) => countByte(firstName + lastName) <= 23,
          message: '「姓」と「名」の文字数がオーバーしています'
        });
      }),
    firstName: yup
      .string()
      .required('「名」が入力されていません')
      .matches(/^[^"',<>&\s*]+$/, '「名」にスペースと特殊文字は使用できません'),
    lastNameKana: yup
      .string()
      .required('「姓(カナ)」が入力されていません')
      .matches(
        /^[^"',<>&\s*]+$/,
        '「姓(カナ)」にスペースと特殊文字は使用できません'
      )
      .matches(
        /^[^\u3040-\u309f\u4e00-\u9fafa-zA-Z]+$/,
        '「姓(カナ)」は全角カナで入力してください'
      )
      .when('firstNameKana', (firstNameKana, schema) => {
        return schema.test({
          test: (lastNameKana: string) => {
            const firstNameAscii = toAscii(firstNameKana);
            const lastNameAscii = toAscii(lastNameKana);
            const firstNameHalfWidth = toHalfwidthKana(firstNameAscii);
            const lastNameHalfWidth = toHalfwidthKana(lastNameAscii);
            return countByte(lastNameHalfWidth + firstNameHalfWidth) <= 23;
          },
          message: '「姓(カナ)」と「名(カナ)」の文字数がオーバーしています'
        });
      }),
    firstNameKana: yup
      .string()
      .required('「名(カナ)」が入力されていません')
      .matches(
        /^[^"',<>&\s*]+$/,
        '「名(カナ)」にスペースと特殊文字は使用できません'
      )
      .matches(
        /^[^\u3040-\u309f\u4e00-\u9fafa-zA-Z]+$/,
        '「名(カナ)」は全角カナで入力してください'
      ),
    tel: yup
      .string()
      .required('「電話番号」が入力されていません')
      .min(10, '正しい電話番号を入力してください')
      .max(11, '正しい電話番号を入力してください')
      .matches(/^[0-9]*$/, '正しい電話番号を入力してください'),
    zipcode: yup
      .string()
      .required('「郵便番号」が入力されていません')
      .min(7, '正しい郵便番号を入力してください')
      .max(7, '正しい郵便番号を入力してください')
      .matches(/^[0-9]*$/, '正しい郵便番号を入力してください')
      .test('valid-zip', '正しい郵便番号を入力してください', (val) => {
        let valid = false;
        postal_code.get(val as string, function (address) {
          valid = Object.keys(address).length !== 0;
        });
        return valid;
      }),
    address1: yup
      .string()
      .required('「住所１」が入力されていません')
      .matches(/^[^"',<>&]+$/, '「住所１」に特殊文字は使用できません')
      .test(
        'check-byte',
        '「住所１」は文字数オーバーです',
        (val) => countByte(val as string) <= 40
      ),
    address2: yup
      .string()
      .required('「住所２」が入力されていません')
      .matches(/^[^"',<>&]+$/, '「住所２」に特殊文字は使用できません')
      .test(
        'check-byte',
        '「住所２」は文字数オーバーです',
        (val) => countByte(val as string) <= 40
      )
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<FormValuesType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => setShowError(false);

  const onSubmit = handleSubmit((data) => {
    setIsProccessing(true);

    let genderValue;
    /* eslint-disable indent */
    switch (data.gender) {
      case 'male':
        genderValue = 2;
        break;
      case 'female':
        genderValue = 1;
        break;
      case 'other':
        genderValue = 0;
        break;
    }

    const requestData = {
      last_name: data.lastName,
      first_name: data.firstName,
      last_name_kana: data.lastNameKana,
      first_name_kana: data.firstNameKana,
      gender: genderValue as number,
      birthday: userData?.birthday as string,
      tel: data.tel,
      postal_code: data.zipcode,
      address_1: data.address1,
      address_2: data.address2
    };
    updateCurrentUser(requestData)
      .then(() =>
        setTimeout(
          () => navigate('/mypage/account?edit=true', { replace: true }),
          300
        )
      )
      .catch(() => setShowError(true));
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    postal_code.get(target.value, function (address) {
      setValue('address1', address.prefecture + address.city + address.area);
    });
  };

  useEffect(() => {
    getCurrentUser()
      .then((res) => {
        const {
          last_name,
          first_name,
          last_name_kana,
          first_name_kana,
          gender,
          tel,
          postal_code,
          address_1,
          address_2
        } = res.data;

        reset({
          lastName: last_name,
          firstName: first_name,
          lastNameKana: last_name_kana,
          firstNameKana: first_name_kana,
          gender: gender,
          tel: tel,
          zipcode: postal_code,
          address1: address_1,
          address2: address_2
        });
        setUserData(res.data);
        setIsFinished(true);
      })
      .then(() => {
        const button = document.querySelector('.js-edit');
        const inputs = document.querySelectorAll('input');
        const selects = document.querySelectorAll('select');
        inputs.forEach((input) => {
          input.addEventListener('input', () => {
            button?.classList.remove('disabled');
          });
        });
        selects.forEach((select) => {
          select.addEventListener('change', () => {
            button?.classList.remove('disabled');
          });
        });
      });
  }, []);

  return (
    <>
      {Object.entries(errors).map((error, ind) => {
        return (
          <AlertMessage
            type="error"
            message={error[1].message as string}
            key={ind}
          />
        );
      })}
      <FullHeightLayout white>
        <Row>
          <Col md={7} className="p-0 mx-auto">
            {isFinished && (
              <>
                <FormReviewItem
                  label="カード番号"
                  value={userData?.card_no as string}
                />
                <FormReviewItem
                  label="PIN番号"
                  value={userData?.pin_code as string}
                />
                <Stack as="form" onSubmit={onSubmit} className="px-3">
                  <Stack direction="horizontal" gap={3}>
                    <TextInput
                      id="lastName"
                      label="姓"
                      className="w-50"
                      {...register('lastName', { required: true })}
                    />
                    <TextInput
                      id="firstName"
                      label="名"
                      className="w-50"
                      {...register('firstName', { required: true })}
                    />
                  </Stack>
                  <Stack direction="horizontal" gap={3}>
                    <TextInput
                      id="lastNameKana"
                      label="姓 (カナ)"
                      className="w-50"
                      {...register('lastNameKana', { required: true })}
                    />
                    <TextInput
                      id="firstNameKana"
                      label="名 (カナ)"
                      className="w-50"
                      {...register('firstNameKana', { required: true })}
                    />
                  </Stack>
                  <TextInput
                    id="tel"
                    type="tel"
                    label="電話番号"
                    caption="※ハイフン（-）は不要です。"
                    {...register('tel', { required: true })}
                  />
                  <TextInput
                    id="zipcode"
                    type="tel"
                    label="郵便番号"
                    caption="※ハイフン（-）は不要です。"
                    {...register('zipcode', {
                      required: true
                    })}
                    onChange={handleChange}
                  />
                  <TextInput
                    id="address1"
                    label="住所１"
                    placeholder="例：◯◯県◯◯市"
                    caption="※都道府県と市区町村をご入力ください"
                    {...register('address1', { required: true })}
                  />
                  <TextInput
                    id="address2"
                    label="住所2"
                    placeholder="例：〇〇〇〇マンション120号"
                    caption="※番地以降をご入力ください。"
                    {...register('address2', { required: true })}
                  />
                  {isProcessing ? (
                    <LoadingButton />
                  ) : (
                    <SubmitButton
                      text="保存"
                      className="mt-5 js-edit"
                      disabled
                      onClick={() => window.scrollTo(0, 0)}
                    />
                  )}
                </Stack>
              </>
            )}
          </Col>
        </Row>
        <ErrorModal
          status={showError}
          message="こちらの機能は、23:00から7:00までご利用になれません。"
          handleClose={handleClose}
        />
      </FullHeightLayout>
    </>
  );
};
export default AccountEdit;
