import React from 'react';

const StringToJsx: React.FC<{ text: string; className?: string }> = ({
  text,
  className
}) => {
  const texts = text.split(/(?:\r\n|\r|\n)/g);
  let classList = 'd-block';
  className && (classList += ` ${className}`);

  return (
    <>
      {texts.map((text, ind) => {
        return (
          <span className={classList} key={`txt-${ind}`}>
            {text || '　'}
          </span>
        );
      })}
    </>
  );
};
export default StringToJsx;
