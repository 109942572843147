import { GlobalState } from 'little-state-machine';

export const initialData: GlobalState = {
  registerCardInput: {
    cardNumber: '',
    lastName: '',
    firstName: '',
    lastNameKana: '',
    firstNameKana: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    gender: undefined,
    tel: '',
    zipcode: '',
    address1: '',
    address2: ''
  },
  store: {
    id: '',
    name: '',
    zipcode: '',
    address: ''
  },
  prepaidBalanceTotal: undefined,
  notificationsUnreadCount: 0,
  notificationsUnreadCountUpdatedAt: undefined
};
