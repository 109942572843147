import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, Tab, Stack, Image } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import CouponItem from 'component/CouponItem';
import BrandSelect from 'component/BrandSelect';
import Loader from 'component/Loader';

import { getCouponList } from 'api';
import { CouponType } from 'types/CouponType';
import { Link } from 'react-router-dom';
import pointCouponBanner from 'assets/image/point_coupon_banner.png';
import campaignBanner from 'assets/image/campaign_banner.png';

const CouponList: React.FC = () => {
  PageTitle('クーポン');

  const [couponList, setCouponList] = useState<Array<CouponType>>([]);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [isMyStore, setIsMyStore] = useState<boolean>(true);

  const handleSelect = (key: string | null) => {
    const isMyStoreTabSelected = key === 'mystore';
    if (isMyStore === isMyStoreTabSelected) return;
    setIsFinished(false);
    setIsMyStore(isMyStoreTabSelected);
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = Number(e.target.value);
    if (selectedBrand === targetValue) return;
    setIsFinished(false);
    setSelectedBrand(targetValue);
  };

  useEffect(() => {
    const coupons = getCouponList(isMyStore, selectedBrand).then((res) => {
      setCouponList(res.data.coupons);
    });
    Promise.allSettled([coupons]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, [isMyStore, selectedBrand]);

  return (
    <MainLayout footer>
      <Row>
        <Col md={7} className="mx-auto">
          <Stack gap={3}>
            {/* コンテンツ */}
            <div>
              {isFinished ? (
                <Tab.Container
                  defaultActiveKey={isMyStore ? 'mystore' : 'all'}
                  id="store-tab"
                  onSelect={handleSelect}
                >
                  <Nav
                    variant="pills"
                    className="mb-3 custom-nav-tab text-center"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="mystore">マイストア</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="all">全店舗</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <BrandSelect
                    half
                    value={selectedBrand}
                    onChange={handleChange}
                  />
                  <Tab.Content>
                    {/* クーポン一覧 */}
                    <Stack gap={3}>
                      {/* デジクルプラスのポイントクーポンバナー */}
                      <Link to="/coupon/digiclueplus/point-coupons">
                        <Image
                          src={pointCouponBanner}
                          alt="ポイントクーポンバナー画像"
                          fluid
                        />
                      </Link>
                      <Link to="/coupon/digiclueplus/campaign">
                        <Image
                          src={campaignBanner}
                          alt="キャンペーンバナー画像"
                          fluid
                        />
                      </Link>
                      {couponList.length === 0 ? (
                        <p className="text--sm mb-0">クーポンがありません。</p>
                      ) : (
                        <>
                          {couponList.map((coupon, index) => {
                            return (
                              <CouponItem
                                id={coupon.id}
                                key={`coupon-${index}`}
                                image={coupon.image_file_name}
                                title={coupon.name}
                                dateStart={coupon.start_at}
                                dateEnd={coupon.end_at}
                              />
                            );
                          })}
                        </>
                      )}
                    </Stack>
                  </Tab.Content>
                </Tab.Container>
              ) : (
                <Loader />
              )}
            </div>
          </Stack>
        </Col>
      </Row>
    </MainLayout>
  );
};
export default CouponList;
