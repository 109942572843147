import React, { useEffect, useState } from 'react';
import { Row, Col, Stack, Accordion } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';
import StoreInfo from 'component/StoreInfo';
import Loader from 'component/Loader';

import ExternalIcon from 'assets/image/icons/external1.svg';
import MapIcon from 'assets/image/icons/map.svg';
import BrandSelect from 'component/BrandSelect';
import MyStoreRemoveModal from 'component/MyStoreRemoveModal';

import {
  getStoreList,
  registerMyStore,
  getMyStoreList,
  deleteMyStore
} from 'api';
import {
  StoreSearchItemType,
  StoreAreaHigherType,
  StoreInfoWithMyStoreFlagType
} from 'types/StoreType';

const StoreSearchItem: React.FC<StoreSearchItemType> = ({
  eventKey,
  title,
  numberOfItems,
  isChild,
  children
}) => {
  return (
    <Accordion.Item
      eventKey={eventKey}
      className={isChild ? 'accordion--custom__child' : ''}
    >
      <Accordion.Header className={'js-accordion'}>
        <Stack direction="horizontal" className="w-100 justify-content-between">
          <div>
            <img src={MapIcon} alt="" className="me-2" />
            {title}
          </div>
          <span className="me-2 search__badge">{numberOfItems}</span>
        </Stack>
      </Accordion.Header>
      <Accordion.Collapse eventKey={eventKey} className="mx-3">
        <>{children}</>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

const StoreSearch: React.FC = () => {
  PageTitle('店舗検索');

  const [show, setShow] = useState<boolean>(false);
  const [areaStores, setAreaStores] = useState<Array<StoreAreaHigherType>>();
  const [myStores, setMyStores] = useState<Array<number>>([]);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [brandId, setBrandId] = useState<number>();
  const [storeIdToDelete, setStoreIdToDelete] = useState<number>();
  const [storeNameToDelete, setStoreNameToDelete] = useState<string>();

  const handleClose = () => setShow(false);

  const handleOpen = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    setShow(true);
    setStoreIdToDelete(parseInt(target.dataset.storeId as string));
    setStoreNameToDelete(target.dataset.storeName);
  };

  const handleAdd = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    const storeId = parseInt(target.dataset.storeId as string);
    registerMyStore(storeId).then(() => {
      const updatedMyStores = [storeId, ...myStores];
      setMyStores(updatedMyStores);
    });
  };

  const handleRemove = () => {
    deleteMyStore(storeIdToDelete as number).then(() => {
      const updatedMyStores = myStores.filter((id) => id !== storeIdToDelete);
      setMyStores(updatedMyStores);
      setShow(false);
    });
  };

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const target = e.target as HTMLSelectElement;
    setBrandId(parseInt(target.value));
  };

  useEffect(() => {
    getMyStoreList().then((res) => {
      const myStoreArr = res.data.stores.map(
        (store: { id: number; name: string }) => store.id
      );
      setMyStores(myStoreArr);
    });
  }, []);

  useEffect(() => {
    getStoreList(brandId).then((res) => {
      const storePerAreaArr: Array<StoreAreaHigherType> = [];

      res.data.stores.map((store: StoreInfoWithMyStoreFlagType) => {
        // Check if registered to Mystore
        if (myStores.includes(store.id)) store.isRegistered = true;

        // Check if has child area
        const hasChildArea = store.areas.length > 1;

        // Check existence of parent area
        const parentArea = storePerAreaArr.filter(
          (storePerArea) => storePerArea.name === store.areas[0]
        )[0];

        if (hasChildArea) {
          if (!parentArea) {
            // Create new storePerArea and push to storePerAreaArr
            const storePerArea = {
              name: store.areas[0],
              numberOfItems: 1,
              areas: [
                {
                  name: store.areas[1],
                  stores: [store],
                  numberOfItems: 1
                }
              ]
            };

            storePerAreaArr.push(storePerArea);
          } else {
            // Check existence of child area
            const childArea = parentArea.areas?.filter(
              (storePerArea) => storePerArea.name === store.areas[1]
            )[0];

            if (!childArea) {
              // Create new storePerChildArea and push to parent in storePerAreaArr
              const storePerChildArea = {
                name: store.areas[1],
                stores: [store],
                numberOfItems: 1
              };

              parentArea.numberOfItems++;
              parentArea.areas?.push(storePerChildArea);
            } else {
              parentArea.numberOfItems++;
              childArea.numberOfItems++;
              childArea.stores?.push(store);
            }
          }
        } else {
          if (!parentArea) {
            // Create new storePerArea and push to storePerAreaArr
            const storePerArea = {
              name: store.areas[0],
              stores: [store],
              numberOfItems: 1
            };

            storePerAreaArr.push(storePerArea);
          } else {
            parentArea.numberOfItems++;
            parentArea.stores?.push(store);
          }
        }
      });
      setAreaStores(storePerAreaArr);
      setTimeout(() => setIsFinished(true), 300);
    });
  }, [brandId, myStores]);

  return (
    <MainLayout className="search" footer>
      <Row className="row--no-mt">
        <Col md={7} className="mx-auto">
          {isFinished ? (
            <>
              <Stack className="mb-4">
                <p className="my-2 ps-3 text--sm">ブランド選択</p>
                <BrandSelect onChange={handleChange} />
              </Stack>
              <p className="my-2 ps-3 text--sm">エリア選択</p>
              <Accordion className="accordion--custom">
                {(areaStores?.length || 0) <= 0 && (
                  <p className="text--sm ms-4 mt-3 mb-0">店舗がありません。</p>
                )}
                {areaStores?.map((area, ind) => {
                  return (
                    <StoreSearchItem
                      eventKey={`parent-${ind}`}
                      key={`parent-${ind}`}
                      title={area.name}
                      numberOfItems={area.numberOfItems}
                    >
                      <Accordion
                        className="accordion--custom"
                        key={`child-${ind}`}
                      >
                        {area.areas?.map((area, ind) => {
                          return (
                            <StoreSearchItem
                              eventKey={`child-${ind}`}
                              key={`child-${ind}`}
                              title={area.name}
                              numberOfItems={area.numberOfItems}
                              isChild={true}
                            >
                              {area.stores?.map((store, ind) => {
                                return (
                                  <StoreInfo
                                    store={store.name}
                                    address={`〒${store.postal_code} ${store.address}`}
                                    time={store.business_hour}
                                    tel={store.tel}
                                    link={`/store/${store.id}`}
                                    lineAccountUrl={store.line_account_url}
                                    className="js-remove"
                                    key={ind}
                                  >
                                    <LinkButton
                                      link={store.tokubai_url ?? ''}
                                      external
                                      text={[
                                        <img
                                          src={ExternalIcon}
                                          alt=""
                                          key={0}
                                          className="me-2"
                                        />,
                                        'チラシを見る'
                                      ]}
                                      type="primary-light"
                                      className="flex-grow-1 w-50 me-4"
                                    />
                                    {store.isRegistered ? (
                                      <LinkButton
                                        link="#"
                                        text="マイストア解除"
                                        type="secondary-light"
                                        data-store-id={store.id}
                                        data-store-name={store.name}
                                        className="flex-grow-1 w-50"
                                        onClick={handleOpen}
                                      />
                                    ) : (
                                      <LinkButton
                                        link="#"
                                        text="マイストア登録"
                                        type="primary-light"
                                        className="flex-grow-1 w-50"
                                        data-store-id={store.id}
                                        onClick={handleAdd}
                                      />
                                    )}
                                  </StoreInfo>
                                );
                              })}
                            </StoreSearchItem>
                          );
                        })}
                      </Accordion>
                      {area.stores?.map((store, ind) => {
                        return (
                          <StoreInfo
                            store={store.name}
                            address={`〒${store.postal_code} ${store.address}`}
                            time={store.business_hour}
                            tel={store.tel}
                            link={`/store/${store.id}`}
                            className="js-remove"
                            key={`store-${ind}`}
                          >
                            <LinkButton
                              link={store.tokubai_url ?? ''}
                              external
                              text={[
                                <img
                                  src={ExternalIcon}
                                  alt=""
                                  key={0}
                                  className="me-2"
                                />,
                                'チラシを見る'
                              ]}
                              type="primary-light"
                              className="flex-grow-1 w-50 me-4"
                            />
                            {store.isRegistered ? (
                              <LinkButton
                                link="#"
                                text="マイストア解除"
                                type="secondary-light"
                                data-store-id={store.id}
                                data-store-name={store.name}
                                className="flex-grow-1 w-50"
                                onClick={handleOpen}
                              />
                            ) : (
                              <LinkButton
                                link="#"
                                text="マイストア登録"
                                type="primary-light"
                                className="flex-grow-1 w-50"
                                data-store-id={store.id}
                                onClick={handleAdd}
                              />
                            )}
                          </StoreInfo>
                        );
                      })}
                    </StoreSearchItem>
                  );
                })}
              </Accordion>
            </>
          ) : (
            <Row className="mt-4">
              <Col md={7} className="mx-auto">
                <Loader />
              </Col>
            </Row>
          )}
          <MyStoreRemoveModal
            storeName={storeNameToDelete as string}
            status={show}
            handleClose={handleClose}
            handleRemove={handleRemove}
          />
        </Col>
      </Row>
    </MainLayout>
  );
};
export default StoreSearch;
