import React from 'react';
import { errorCodes } from 'lib/errorCodes';
import { useSearchParams } from 'react-router-dom';
import StatusImage, { ImageType } from 'component/StatusImage';
import { LinkButton } from 'component/Button';
import { Row, Col } from 'react-bootstrap';
import StringToJsx from 'component/StringToJsx';

/**
 * VDエラー（連携先サーバーのエラー）時に表示する画面
 * * VDエラーの場合、以下のエラーコードが返ってくる
 * * G列に「画面遷移して表示するエラー」と記載のあるエラーコードの場合、本画面が表示される
 * https://docs.google.com/spreadsheets/d/1Xov7Fp-qDGX7FDHfkPvAQX9z1B90omRLrPSIn5Pdhxs/edit#gid=1054226400
 */
const VDError: React.FC<{
  type?: Exclude<ImageType['type'], 'cardSuccess'>;
}> = ({ type = 'cardFail' }) => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') as keyof typeof errorCodes;

  return (
    <>
      <Row className="flex-grow-1 align-items-center">
        <Col className="mx-auto">
          <div>
            <StatusImage
              // プリカチャージ失敗の場合、警告画像を表示する
              type={type}
              text={errorCodes[code].message ?? 'エラー'}
            />
            <span className="d-block text-center px-4">
              <StringToJsx
                text={
                  errorCodes[code].desc ??
                  'エラーが発生しました。\n TOPに戻ってやり直してください。'
                }
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row className="bottom-fix mb-3">
        <Col md={7} className="mx-auto">
          <LinkButton type="secondary-outline" link="/" text="ホームへ" />
        </Col>
      </Row>
    </>
  );
};

export default VDError;
