import React from 'react';
import CustomRoutes from 'routes';
import { StateMachineProvider, createStore } from 'little-state-machine';

import { initialData } from 'lib/stateData';

import 'assets/style/App.scss';

createStore(initialData, {});

const App: React.FC = () => {
  return (
    <StateMachineProvider>
      <CustomRoutes />
    </StateMachineProvider>
  );
};

export default App;
