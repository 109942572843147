import React, { useEffect, useState } from 'react';
import { Form, FormSelectProps } from 'react-bootstrap';

import { getBrandList } from 'api';
import { BrandResponseType } from 'types/BrandType';

interface BrandSelectType extends FormSelectProps {
  half?: true;
  value?: number;
}

const BrandSelect = React.forwardRef<HTMLSelectElement, BrandSelectType>(
  ({ half, value, ...props }, ref) => {
    const [brandOptions, setBrandOptions] = useState<Array<BrandResponseType>>(
      []
    );

    useEffect(() => {
      getBrandList().then((res) => {
        setBrandOptions(res.data.brands);
      });
    }, []);

    let classList = 'mb-4';
    half && (classList += ' w-50');

    return (
      <Form.Select
        aria-label="ブランド選択"
        className={classList}
        {...props}
        ref={ref}
        value={value}
      >
        <option value="">ブランド選択</option>
        {brandOptions.map((brand, ind) => {
          return (
            <option value={brand.id} key={ind}>
              {brand.name}
            </option>
          );
        })}
      </Form.Select>
    );
  }
);

BrandSelect.displayName = 'SelectInput';
export default BrandSelect;
