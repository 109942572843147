import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Stack } from 'react-bootstrap';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { SMARTRECEIPT_LINK } from 'lib/constants';

import { openWindow } from 'lib';

const SmartReceiptNotice: React.FC = () => {
  PageTitle('ご注意');
  const navigate = useNavigate();

  return (
    <FullHeightLayout white>
      <Row className="justify-content-center mt-3">
        <Col md={7} className="px-4">
          <Stack gap={5}>
            <Stack gap={4}>
              <h2 className="text-center fs-6">電子レシートのご利用について</h2>
              <p className="mb-0">
                電子レシートは、東芝テック株式会社が提供する「スマートレシート」となります。
                <br />
                「スマートレシート」では、紙のレシートの代わりに電子レシートをスマートフォンでご確認いただけるようになります。
                <br />
                <br />
                電子レシートをご利用いただくには、「スマートレシート」に新規登録の上、マキヤプリカカード番号との連携が必要となります。
                <br />
                <br />
                「スマートレシート」会員ではない方は、新規会員登録を済ませた上で、「はい」を選んでください。
              </p>
            </Stack>
            <Stack gap={4}>
              <p className="text-center text--md mb-0">
                「スマートレシート」に会員登録していますか？
              </p>
              <div className="d-grid">
                <Button
                  variant="-primary"
                  className="btn--custom p-3"
                  onClick={() => navigate('/smart-receipt/connect')}
                >
                  はい
                </Button>
              </div>
              <div className="d-grid">
                <Button
                  variant="-primary"
                  className="btn--custom p-2 lh-sm"
                  onClick={() => openWindow(SMARTRECEIPT_LINK)}
                >
                  いいえ
                  <br />
                  <span className="text--sm text-white">
                    ※スマートレシートサイトで会員登録を行ってください。
                  </span>
                </Button>
              </div>
            </Stack>
          </Stack>
        </Col>
      </Row>
    </FullHeightLayout>
  );
};

export default SmartReceiptNotice;
