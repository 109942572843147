import { initialData } from './stateData';
import { GlobalState } from 'little-state-machine';
import dayjs from 'dayjs';

export const updateAction = (
  state: GlobalState,
  payload: any /* eslint-disable-line @typescript-eslint/no-explicit-any */
) => ({
  ...state,
  ...payload
});

export const clearAction = (
  state: GlobalState /* eslint-disable-line @typescript-eslint/no-unused-vars */,
  payload: any /* eslint-disable-line @typescript-eslint/no-unused-vars */ /* eslint-disable-line @typescript-eslint/no-explicit-any */
) => ({
  ...initialData
});

export function updateNotificationsUnreadCount(
  state: GlobalState,
  payload: number
): GlobalState {
  return {
    ...state,
    notificationsUnreadCount: payload,
    notificationsUnreadCountUpdatedAt: dayjs().toISOString()
  };
}

export function clearNotificationsUnreadCountUpdatedAt(
  state: GlobalState
): GlobalState {
  return {
    ...state,
    notificationsUnreadCountUpdatedAt: undefined
  };
}
