import React from 'react';
import liff from '@line/liff/dist/lib';
import { Row, Col } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';

const SessionExpired: React.FC = () => {
  return (
    <MainLayout>
      <Row className="row">
        <Col md={7} className="mx-auto">
          <h3>セッションの有効切れ</h3>
          <p>
            セッションの有効期限が切れています。
            <br />
            アプリを再起動してください。
          </p>
          <a
            href="#"
            onClick={() => liff.closeWindow()}
            className="text-decoration-underline"
          >
            アプリを閉じる
          </a>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default SessionExpired;
