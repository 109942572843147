import React, { useEffect, useState } from 'react';
import { Stack, Row, Col } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import Loader from 'component/Loader';

import { getCardHistory, getCurrentPrepaid } from 'api';
import {
  CardInfoType,
  CardHistoryItemType,
  TransactionDataType
} from 'types/CardHistoryType';
import { formatDate } from 'lib';
import { checkTransaction, getTransactionAmount } from 'lib/cardHistory';

const CardInfo: React.FC<CardInfoType> = ({
  balance,
  balanceBasic,
  basicDate,
  balanceBonus,
  bonusDate,
  balanceCoupon,
  couponDate
}) => {
  return (
    <Stack className="p-3 mb-4 section-cont section-cont--rounded-2 card-info">
      <p className="m-0">マキヤプリカ残高</p>
      <Stack
        direction="horizontal"
        className="mb-1 align-items-center justify-content-center"
      >
        <p className="m-0 text-end card-info__balance">
          {balance}
          <span className="card-info__balance-unit">円</span>
        </p>
      </Stack>
      <Stack direction="horizontal">
        <Stack className="text-center ver-separator">
          <span className="info__label">
            チャージ
            <br />
            残高
          </span>
          <span className="info__value">
            {balanceBasic}
            <span className="text--md">円</span>
          </span>
          {parseInt(balanceBasic) > 0 && (
            <span className="info__date">{basicDate}まで</span>
          )}
        </Stack>
        <Stack className="text-center ver-separator align-self-end">
          <span className="info__label">
            ボーナス
            <br />
            残高
          </span>
          <span className="info__value">
            {balanceBonus}
            <span className="text--sm">円</span>
          </span>
          {parseInt(balanceBonus) > 0 && (
            <span className="info__date">{bonusDate}まで</span>
          )}
        </Stack>
        <Stack className="text-center align-self-end">
          <span className="info__label">
            クーポン
            <br />
            残高
          </span>
          <span className="info__value">
            {balanceCoupon}
            <span className="text--sm">円</span>
          </span>
          {parseInt(balanceCoupon) > 0 && (
            <span className="info__date">{couponDate}まで</span>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const CardHistoryItem: React.FC<CardHistoryItemType> = ({
  date,
  store,
  status,
  amount,
  isAdd,
  balance
}) => {
  return (
    <Stack className="pb-2 pt-3 history-item">
      <Stack direction="horizontal">
        <span className="badge me-2 history-item__badge">{status}</span>
        <span className="text--sm history-item__date">{date}</span>
      </Stack>
      <Stack
        direction="horizontal"
        className="justify-content-between align-items-baseline"
      >
        <span className="history-item__store">{store}</span>
        <span
          className={`text--sm history-item__amount ${
            isAdd ? 'add' : 'remove'
          }`}
        >
          <span className="text--lg">
            {isAdd ? '+' : '-'} {amount}
          </span>
          <span>円</span>
        </span>
      </Stack>
      <span className="text-end history-item__balance">残高：{balance}円</span>
    </Stack>
  );
};

const CardHistory: React.FC = () => {
  PageTitle('プリペイド履歴');

  const [cardBalance, setCardBalance] = useState<CardInfoType>();
  const [historyArr, setHistoryArr] = useState<Array<CardHistoryItemType>>([]);
  const [histories, setHistories] = useState<Array<CardHistoryItemType>>([]);
  const [paging, setPaging] = useState<{ limit: number; offset: number }>({
    limit: 300,
    offset: 0
  });
  const [isHistoryFinished, setIsHistoryFinished] = useState<boolean>(false);
  const [isLoadingNext, setIsLoadingNext] = useState<boolean>(true);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  useEffect(() => {
    const cardBalance = getCurrentPrepaid().then((res) => {
      const {
        balance_total,
        balance_basic,
        balance_bonus,
        balance_coupon,
        expire_date_basic,
        expire_date_bonus,
        expire_date_coupon
      } = res.data;
      const balance: CardInfoType = {
        balance: balance_total.toLocaleString(),
        balanceBasic: balance_basic.toLocaleString(),
        balanceBonus: balance_bonus.toLocaleString(),
        balanceCoupon: balance_coupon.toLocaleString(),
        basicDate: formatDate(expire_date_basic, false, false),
        bonusDate: formatDate(expire_date_bonus, false, false),
        couponDate: formatDate(expire_date_coupon, false, false)
      };
      setCardBalance(balance);
    });

    Promise.allSettled([cardBalance]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, []);

  useEffect(() => {
    getCardHistory(paging).then((res) => {
      if (res.data.records.length) {
        const filtered = res.data.records
          .filter((trans: TransactionDataType) => {
            // 表示しない取引種類
            const ignoreTrans = [
              '4001',
              '4007',
              '4008',
              '4808',
              '4811',
              '4812',
              '4899',
              '9801'
            ];
            return !ignoreTrans.includes(trans.req_class);
          })
          .map((trans: TransactionDataType) => {
            const amount = getTransactionAmount(trans);
            const isAdd = checkTransaction(trans.req_class);

            return {
              date: formatDate(trans.reg_date as string, true, true),
              store: trans.shop_name,
              status: trans.req_class_exp,
              amount: amount,
              isAdd: isAdd,
              balance: trans.balance_total
            };
          });
        setHistoryArr(filtered);
      } else {
        setIsLoadingNext(false);
      }
      setIsHistoryFinished(true);
    });
  }, [paging]);

  useEffect(() => {
    setPaging({
      limit: paging.limit,
      offset: paging.offset + paging.limit
    });
    setHistories((prevState) => [...historyArr, ...prevState]);
  }, [historyArr]);

  return (
    <MainLayout className="history" footer>
      <Row>
        <Col md={7} className="mx-auto">
          {isFinished ? (
            <>
              <CardInfo
                balance={(cardBalance?.balance as string) || '0'}
                balanceBasic={(cardBalance?.balanceBasic as string) || '0'}
                basicDate={cardBalance?.basicDate as string}
                balanceBonus={(cardBalance?.balanceBonus as string) || '0'}
                bonusDate={cardBalance?.bonusDate as string}
                balanceCoupon={(cardBalance?.balanceCoupon as string) || '0'}
                couponDate={cardBalance?.couponDate as string}
              />
              <Stack className="p-3 pt-2 mb-4 section-cont section-cont--rounded-2">
                {isHistoryFinished && (
                  <>
                    {isLoadingNext ? (
                      <div className="pb-2 pt-3 ">
                        <Loader />
                      </div>
                    ) : (
                      <>
                        {(histories?.length || 0) <= 0 && (
                          <p className="text--sm mb-0">履歴がありません。</p>
                        )}
                      </>
                    )}
                    {histories?.map((history: CardHistoryItemType, ind) => {
                      return (
                        <CardHistoryItem
                          date={history.date}
                          store={history.store}
                          status={history.status}
                          amount={history.amount}
                          isAdd={history.isAdd}
                          balance={history.balance}
                          key={`history-${ind}`}
                        />
                      );
                    })}
                  </>
                )}
              </Stack>
            </>
          ) : (
            <Row className="mt-4">
              <Col md={7} className="mx-auto">
                <Loader />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </MainLayout>
  );
};
export default CardHistory;
