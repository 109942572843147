import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';
import PageTitle from 'component/PageTitle';
import SnackBar from 'component/SnackBar';
import StoreInfo from 'component/StoreInfo';
import { LinkButton } from 'component/Button';
import MyStoreRemoveModal from 'component/MyStoreRemoveModal';
import Loader from 'component/Loader';

import { getMyStoreList, getStoreInfo, deleteMyStore } from 'api';
import { StoreInfoType } from 'types/StoreType';

import ExternalIcon from 'assets/image/icons/external1.svg';
import MagnifyIcon from 'assets/image/icons/magnify-1.svg';

const MyStore: React.FC = () => {
  PageTitle('マイストア');

  const [show, setShow] = useState<boolean>(false);
  const [parentElem, setParentElem] = useState<Element | null>();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [storeList, setStoreList] = useState<Array<StoreInfoType>>();
  const [storeId, setStoreId] = useState<number>();
  const [storeName, setStoreName] = useState<string>();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const handleClose = () => setShow(false);

  const handleRemove = () => {
    parentElem?.remove();
    deleteMyStore(storeId as number);
    setShow(false);
    setShowSnackBar(true);
  };

  const isClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;
    e.preventDefault();
    setStoreId(parseInt(target.dataset.storeId as string));
    setStoreName(target.dataset.storeName);
    setShow(true);
    setParentElem(target.closest('.js-remove'));
  };

  useEffect(() => {
    const mystore = getMyStoreList().then((res) => {
      Promise.all(
        res.data.stores.map((store: { id: number }) => {
          return getStoreInfo(store.id).then((res) => res.data);
        })
      ).then((data) => setStoreList(data));
    });

    Promise.allSettled([mystore]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, []);

  return (
    <MainLayout footer>
      <Row>
        <Col md={7} className="mx-auto">
          {isFinished ? (
            <>
              {storeList?.map((store, ind) => {
                return (
                  <StoreInfo
                    store={store.name}
                    address={`〒${store.postal_code} ${store.address}`}
                    time={store.business_hour}
                    tel={store.tel}
                    link={`/store/${store.id}`}
                    className="js-remove"
                    lineAccountUrl={store.line_account_url}
                    key={ind}
                  >
                    <LinkButton
                      link={store.tokubai_url ?? ''}
                      external
                      text={[
                        <img
                          src={ExternalIcon}
                          alt=""
                          key={0}
                          className="me-2"
                        />,
                        'チラシを見る'
                      ]}
                      type="primary-light"
                      className="flex-grow-1 w-50 me-4"
                    />
                    <LinkButton
                      link="#"
                      text="マイストア解除"
                      type="secondary-light"
                      data-store-id={store.id}
                      data-store-name={store.name}
                      className="flex-grow-1 w-50"
                      onClick={isClicked}
                    />
                  </StoreInfo>
                );
              })}
              <LinkButton
                link="/mypage/search"
                type="primary-outline"
                className="mt-5 bg-white"
                text={[
                  <img src={MagnifyIcon} alt="" key={0} className="me-2" />,
                  '他の店舗を探す'
                ]}
              />
            </>
          ) : (
            <Loader />
          )}
          <SnackBar
            message={`${storeName}を削除しました`}
            show={showSnackBar}
          />
          <MyStoreRemoveModal
            storeName={storeName as string}
            status={show}
            handleClose={handleClose}
            handleRemove={handleRemove}
            onExited={() => setShowSnackBar(false)}
          />
        </Col>
      </Row>
    </MainLayout>
  );
};
export default MyStore;
