import React from 'react';
import { Stack } from 'react-bootstrap';
import { Link, LinkProps } from 'react-router-dom';

import { openWindow } from 'lib';

interface MenuItemType extends Omit<LinkProps, 'to' | 'className'> {
  link?: string;
  className?: string;
  external?: true;
}

export const MenuItem: React.FC<MenuItemType> = ({
  link,
  className,
  external,
  children,
  ...props
}) => {
  let classList = 'menu__items';
  className && (classList += ` ${className}`);

  return (
    <>
      {link ? (
        <>
          {!external ? (
            <Link to={link} className={classList} {...props}>
              <Stack direction="horizontal" className="justify-content-between">
                {children}
              </Stack>
            </Link>
          ) : (
            <a
              href="#"
              className={classList}
              {...props}
              onClick={() => openWindow(link)}
            >
              <Stack direction="horizontal" className="justify-content-between">
                {children}
              </Stack>
            </a>
          )}
        </>
      ) : (
        <div className={classList}>
          <Stack direction="horizontal" className="justify-content-between">
            {children}
          </Stack>
        </div>
      )}
    </>
  );
};

export const MenuGroup: React.FC = ({ children }) => {
  return <Stack className="full-width menu">{children}</Stack>;
};
