import React from 'react';
import PageTitle from 'component/PageTitle';
import { Stack, Row, Col } from 'react-bootstrap';
import { LinkButton } from 'component/Button';
import FullHeightLayout from 'layout/FullHeightLayout';
import { useSearchParams } from 'react-router-dom';
import VDError from 'page/VDError';
import CheckIcon from 'assets/image/icons/check.svg';

const ExchangeSuccess: React.FC = () => {
  PageTitle('ポイントチャージ完了');

  return (
    <>
      <Row className="flex-grow-1 align-items-center">
        <Col md={7} className="mx-auto">
          <Stack className="align-items-center">
            <img src={CheckIcon} alt="チェック" className="mb-4" />
            <p className="text--lg">ポイントチャージが完了しました</p>
            <p className="text--md text--secondary-light">
              次回のお買い物を心よりお待ちしております。
            </p>
          </Stack>
        </Col>
      </Row>
      <Row className="bottom-fix mb-3">
        <Col md={7} className="mx-auto">
          <Stack>
            <LinkButton link="/" text="ホームへ" />
          </Stack>
        </Col>
      </Row>
    </>
  );
};

/** プリカチャージ ポイントチャージ完了画面 */
const PrepaidChargeComplete: React.FC = () => {
  PageTitle('ポイントチャージ完了');

  const [searchParams] = useSearchParams();

  const isFail = searchParams.get('fail') === 'true';

  return (
    <FullHeightLayout white>
      {isFail ? <VDError type="pointExchangeFail" /> : <ExchangeSuccess />}
    </FullHeightLayout>
  );
};

export default PrepaidChargeComplete;
