import React from 'react';
import { Stack } from 'react-bootstrap';

import { CenterModal } from 'component/Modals';

interface MyStoreRemoveModalType {
  storeName: string;
  status: boolean;
  handleClose: React.MouseEventHandler<HTMLAnchorElement>;
  handleRemove: React.MouseEventHandler<HTMLAnchorElement>;
  onExited?: (node: HTMLElement) => void;
}

const MyStoreRemoveModal: React.FC<MyStoreRemoveModalType> = ({
  storeName,
  status,
  handleClose,
  handleRemove,
  onExited
}) => {
  return (
    <CenterModal status={status} onExited={onExited}>
      <p className="text--lg">{storeName}</p>
      <p className="mb-5 text--secondary">
        選択した店舗をマイストアから削除しますか？
      </p>
      <Stack direction="horizontal" className="justify-content-around">
        <a href="#" className="w-50 text-center text--md" onClick={handleClose}>
          キャンセル
        </a>
        <a
          href="#"
          className="w-50 text-center text--md text--primary"
          onClick={handleRemove}
        >
          削除
        </a>
      </Stack>
    </CenterModal>
  );
};
export default MyStoreRemoveModal;
