import React, { useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';
import NewsItem from 'component/NewsItem';
import CouponItem from 'component/CouponItem';
import SnackBar from 'component/SnackBar';
import FullBanner from 'component/FullBanner';

import {
  getStoreInfo,
  getMyStoreList,
  registerMyStore,
  deleteMyStore,
  getStoreNews
} from 'api';
import { StoreInfoType } from 'types/StoreType';
import { NewsType } from 'types/NewsType';

import ChevronRightIcon from 'assets/image/icons/chevron_right.svg';
import CarIcon from 'assets/image/icons/car.svg';
import ClockIcon from 'assets/image/icons/clock.svg';
import TelIcon from 'assets/image/icons/tel.svg';
import ExternalIcon from 'assets/image/icons/external1.svg';
import Loader from 'component/Loader';
import LineIcon from 'assets/image/icons/line_icon.png';

const StoreDetail: React.FC = () => {
  PageTitle('店舗詳細');

  const { id } = useParams();
  const [storeInfo, setStoreInfo] = useState<StoreInfoType>();
  const [registeredStore, setRegisteredStore] = useState<Array<number>>([]);
  const [showSnackBar, setShowSnackBar] = useState<boolean>(false);
  const [snackBarText, setSnackBarText] = useState<string>('');
  const [newsList, setNewsList] = useState<Array<NewsType>>();
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const handleAdd = () => {
    registerMyStore(storeInfo?.id as number).then(() => {
      const updatedMyStores: Array<number> = [
        storeInfo?.id as number,
        ...registeredStore
      ];
      setRegisteredStore(updatedMyStores);
      setSnackBarText('追加しました');
      setShowSnackBar((prevState) => !prevState);
    });
  };

  const handleRemove = () => {
    deleteMyStore(storeInfo?.id as number).then(() => {
      const updatedMyStores = registeredStore.filter(
        (id) => id !== storeInfo?.id
      );
      setRegisteredStore(updatedMyStores);
      setSnackBarText('削除しました');
      setShowSnackBar((prevState) => !prevState);
    });
  };

  useEffect(() => {
    const store = getStoreInfo(parseInt(id as string)).then((res) => {
      setStoreInfo(res.data);
    });

    const news = getStoreNews(parseInt(id as string)).then((res) => {
      setNewsList(res.data.notifications);
    });

    const myStoreArr: Array<number> = [];
    const myStore = getMyStoreList().then((res) => {
      res.data.stores.map((store: { id: number; name: string }) =>
        myStoreArr.push(store.id)
      );
      setRegisteredStore(myStoreArr);
    });

    Promise.allSettled([store, news, myStore]).then(() => {
      setTimeout(() => setIsFinished(true), 300);
    });
  }, []);

  return (
    <FullHeightLayout className="pt-0">
      {isFinished ? (
        <>
          <Row className="bg-white mb-5">
            <Col md={7} className="mx-auto">
              <FullBanner img={storeInfo?.image_file_name as string} />
              <div className="my-2">
                <div className="position-relative store-card">
                  <div
                    className={storeInfo?.line_account_url && 'store-card__top'}
                  >
                    <h4 className="text--lg text--secondary">
                      {storeInfo?.name}
                    </h4>
                    <p className="mb-4 text--secondary-light">
                      〒{storeInfo?.postal_code} {storeInfo?.address}
                    </p>
                    {storeInfo?.line_account_url && (
                      <a
                        className="position-absolute end-0 top-0"
                        href={storeInfo?.line_account_url}
                      >
                        <img
                          className="line-account-icon ratio-1x1"
                          src={LineIcon}
                          alt={`${storeInfo?.name}公式LINEアカウント`}
                        />
                      </a>
                    )}
                  </div>
                </div>
                {registeredStore?.includes(storeInfo?.id as number) ? (
                  <LinkButton
                    link="#"
                    type="secondary-outline"
                    text="マイストア削除"
                    className="mb-4"
                    onClick={handleRemove}
                  />
                ) : (
                  <LinkButton
                    link="#"
                    type="primary-outline"
                    text="マイストア登録"
                    className="mb-4"
                    onClick={handleAdd}
                  />
                )}
                <LinkButton
                  link={storeInfo?.tokubai_url ?? ''}
                  external
                  type="primary-light"
                  text={[
                    <img src={ExternalIcon} alt="" key={0} className="me-2" />,
                    'チラシを見る'
                  ]}
                  className="mb-4"
                />
                <p className="mb-3 text--secondary-light">
                  <img src={ClockIcon} alt="" className="me-1" />
                  {storeInfo?.business_hour}
                </p>
                <p className="mb-3 text--secondary-light">
                  <img src={TelIcon} alt="" className="me-1" />
                  {storeInfo?.tel}
                </p>
                <p className="mb-3 text--secondary-light">
                  <img src={CarIcon} alt="" className="me-1" />
                  {parseInt(storeInfo?.parking as string) > 0
                    ? `駐車場 有り（ ${storeInfo?.parking} 台）`
                    : '駐車場 無し'}
                </p>
              </div>
              <div className="mb-4">
                <p>{storeInfo?.description}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={7} className="mx-auto">
              <Stack className="mb-3">
                <Stack
                  direction="horizontal"
                  className="mb-3 justify-content-between"
                >
                  <p className="mb-0">利用可能クーポン</p>
                  <Stack
                    direction="horizontal"
                    as="a"
                    href="/coupon"
                    className="align-items-center text--secondary-light text--sm"
                  >
                    もっと見る
                    <img src={ChevronRightIcon} alt="" className="ms-2" />
                  </Stack>
                </Stack>
                {(storeInfo?.coupons?.length || 0) <= 0 && (
                  <p className="text--sm mb-0">クーポンがありません。</p>
                )}
                {storeInfo?.coupons?.map((coupon, ind) => {
                  return (
                    <CouponItem
                      id={coupon.id}
                      key={`coupon-${ind}`}
                      image={coupon.image_file_name.url}
                      title={coupon.name}
                      dateStart={coupon.start_at}
                      dateEnd={coupon.end_at}
                    />
                  );
                })}
              </Stack>
              <Stack className="mb-3">
                <Stack
                  direction="horizontal"
                  className="mb-3 justify-content-between"
                >
                  <p className="mb-0">お知らせ</p>
                  <Stack
                    direction="horizontal"
                    as="a"
                    href="/news"
                    className="align-items-center text--secondary-light text--sm"
                  >
                    もっと見る
                    <img src={ChevronRightIcon} alt="" className="ms-2" />
                  </Stack>
                </Stack>
                {(newsList?.length || 0) <= 0 && (
                  <p className="text--sm mb-0">お知らせがありません。</p>
                )}
                {newsList?.map((news, ind) => {
                  return (
                    <NewsItem
                      id={news.id}
                      title={news.title}
                      date={news.published_at}
                      key={`news-${ind}`}
                      isUnread={news.is_unread}
                    />
                  );
                })}
              </Stack>
              <SnackBar
                message={`${storeInfo?.name}を${snackBarText}`}
                show={showSnackBar}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Loader />
      )}
    </FullHeightLayout>
  );
};
export default StoreDetail;
