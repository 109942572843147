import React, { useEffect, useRef } from 'react';
import {
  createScriptElement,
  appendScripts,
  removeScripts
} from 'lib/scriptHandler';

interface AdPopupType {
  adSrc: string;
  gcmSrc: string;
}

const AdPopup: React.FC<AdPopupType> = ({ adSrc, gcmSrc }) => {
  const adDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const adLoaderScript = createScriptElement(adSrc, true);
    const gcmLoaderScript = createScriptElement(gcmSrc, true);

    if (adDiv.current) {
      appendScripts(adDiv.current, [adLoaderScript, gcmLoaderScript]);
    }

    return () => {
      if (adDiv.current) {
        removeScripts(adDiv.current, [adLoaderScript, gcmLoaderScript]);
      }
    };
  }, []);

  return (
    <div
      ref={adDiv}
      className="gsm-gcm-ad"
      style={{ margin: '0 auto', padding: 0, position: 'relative' }}
    ></div>
  );
};

export default AdPopup;
