import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

export const CenterModal: React.FC<{ status: boolean } & ModalProps> = ({
  status,
  children,
  ...props
}) => {
  return (
    <Modal
      show={status}
      animation={true}
      className="modal--custom"
      centered
      {...props}
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export const BottomModal: React.FC<{ status: boolean } & ModalProps> = ({
  status,
  children,
  ...props
}) => {
  return (
    <Modal
      show={status}
      animation={true}
      className="modal--custom-bottom"
      {...props}
    >
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
