import React from 'react';
import { Stack, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import TextInput from 'component/TextInput';
import AlertMessage from 'component/AlertMessage';
import { SubmitButton } from 'component/Button';

/**
 * カード（マキヤプリカ）連携画面
 * - カード番号・PIN番号を入力する画面
 * - 以下のケースで表示される
 *   - 初回起動画面で「すでにマキヤプリカをお持ちの方」をクリック
 *   - カード変更画面で「別のカードを連携する」をクリック
 */
const CardConnect: React.FC = () => {
  PageTitle('マキヤプリカ連携');

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();

  const urlQuery =
    searchParams.get('event') === 'reissue'
      ? 'event=' + searchParams.get('event')
      : '';

  const validationSchema = yup.object({
    cardNumber: yup
      .string()
      .required('カード番号入力されていません')
      .min(13, '正しいカード番号を入力してください')
      .max(13, '正しいカード番号を入力してください')
      .matches(/^22[0-9]*$/, '正しいカード番号を入力してください'),
    pinNumber: yup
      .string()
      .required('PIN番号が入力されていません')
      .min(6, '正しいPIN番号を入力してください')
      .max(6, '正しいPIN番号を入力してください')
      .matches(/^[0-9]*$/, '正しいPIN番号を入力してください')
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ cardNumber: string; pinNumber: string }>({
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = handleSubmit((data) => {
    navigate('/validate?' + urlQuery, {
      state: {
        card_no: data.cardNumber,
        pin_code: data.pinNumber,
        ...(state as {
          utm_source?: string;
          utm_medium?: string;
          utm_campaign?: string;
          utm_content?: string;
        })
      }
    });
  });

  return (
    <FullHeightLayout white>
      {Object.keys(errors).length !== 0 && (
        <div className="mb-4">
          {Object.entries(errors).map((error, ind) => {
            return (
              <AlertMessage
                type="error"
                message={error[1].message as string}
                key={ind}
              />
            );
          })}
        </div>
      )}
      <Stack as="form" onSubmit={onSubmit}>
        <Row>
          <Col md={7} className="mx-auto">
            <TextInput
              type="tel"
              id="cardNumber"
              label="カード番号"
              placeholder="例：2200000000000"
              {...register('cardNumber')}
            />
            <TextInput
              type="tel"
              id="pinNumber"
              label="PIN番号"
              placeholder="例：000000"
              {...register('pinNumber')}
            />
            <p className="fw-bold">
              マキヤプリカの連携には発行からおよそ１か月ほどかかります。
            </p>
          </Col>
        </Row>
        <Row className="bottom-fix mb-3">
          <Col md={7} className="mx-auto">
            <Stack>
              <SubmitButton text="本人確認に進む" className="js-test" />
            </Stack>
          </Col>
        </Row>
      </Stack>
    </FullHeightLayout>
  );
};
export default CardConnect;
