import React, { useEffect, useState } from 'react';
import { Stack, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import TextInput from 'component/TextInput';
import AlertMessage from 'component/AlertMessage';
import { LoadingButton, SubmitButton } from 'component/Button';
import ErrorModal from 'component/ErrorModal';

import { API_SUCCEEDED } from 'lib/constants';
import { registerUser, reissueCard } from 'api';
import { errorCodes } from 'lib/errorCodes';

interface LocationStateType {
  card_no: string;
  pin_code: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

/**
 * 本人確認画面
 * - 生年月日を入力する
 * - カード（マキヤプリカ）連携画面で「本人確認に進む」をクリック後、表示される
 */
const CardValidate: React.FC = () => {
  PageTitle('本人確認');

  const navigate = useNavigate();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();

  const [showError, setShowError] = useState<boolean>(false);
  const [errCode, setErrCode] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { card_no, pin_code } = state as LocationStateType;

  const validationSchema = yup.object({
    birthYear: yup
      .string()
      .required('生年月日(年)が入力されていません')
      .max(4, '正しい生年月日(年)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(年)を入力してください')
      .when(['birthMonth', 'birthDay'], {
        is: (birthMonth: string, birthDay: string) => birthMonth && birthDay,
        then: yup
          .string()
          .test('birthYear', '正しい生年月日を入力してください', function (y) {
            const m = this.parent.birthMonth;
            const d = this.parent.birthDay;

            const date = new Date(`${y}/${m}/${d}`);

            if (
              (date.getFullYear() === 1900 &&
                date.getMonth() + 1 === 1 &&
                date.getDate() === 1) ||
              date.getFullYear() < 1900
            ) {
              return false;
            } else {
              return (
                date.getFullYear() === parseInt(y as string) &&
                date.getMonth() + 1 === parseInt(m) &&
                date.getDate() === parseInt(d)
              );
            }
          })
      }),
    birthMonth: yup
      .string()
      .required('生年月日(月)が入力されていません')
      .max(2, '正しい生年月日(月)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(月)を入力してください'),
    birthDay: yup
      .string()
      .required('生年月日(日)が入力されていません')
      .max(2, '正しい生年月日(日)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(日)を入力してください')
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<{
    cardNumber: string;
    birthYear: string;
    birthMonth: string;
    birthDay: string;
  }>({
    criteriaMode: 'all',
    shouldFocusError: false,
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  });

  const handleClose = () => setShowError(false);

  const onSubmit = handleSubmit((data) => {
    setIsProcessing(true);
    const payload = {
      birthday:
        data.birthYear +
        data.birthMonth.padStart(2, '0') +
        data.birthDay.padStart(2, '0'),
      ...(state as LocationStateType)
    };
    if (searchParams.get('event')) {
      reissueCard(payload)
        .then((res) => {
          if (res.status === API_SUCCEEDED) {
            setTimeout(() => navigate('/complete'), 300);
          } else {
            /** エラーコードを参照して、エラーメッセージを設定する */
            const code = res.error_code as keyof typeof errorCodes;
            const formError = ['001', '002', '003', '004', '018'];

            if (formError.includes(code)) {
              /** フォームのエラメッセージとして出す */
              setError('cardNumber', {
                type: 'custom',
                message: errorCodes[code].desc
              });
              setErrCode(code);
              setTimeout(() => setIsProcessing(false), 300);
            } else {
              /** 失敗画面に移動 */
              setTimeout(
                () => navigate(`/complete?fail=true&code=${code}`),
                300
              );
            }
          }
        })
        .catch(() => setShowError(true));
    } else {
      registerUser(payload)
        .then((res) => {
          if (res.status === API_SUCCEEDED) {
            setTimeout(() => navigate('/complete'), 300);
          } else {
            /** エラーコードを参照して、エラーメッセージを設定する */
            const code = res.error_code as keyof typeof errorCodes;
            const formError = ['001', '002', '003', '004', '005'];

            if (formError.includes(code)) {
              /** フォームのエラメッセージとして出す */
              setError('cardNumber', {
                type: 'custom',
                message: errorCodes[code].desc
              });
              setErrCode(code);
              setTimeout(() => setIsProcessing(false), 300);
            } else {
              /** 失敗画面に移動 */
              setTimeout(
                () => navigate(`/complete?fail=true&code=${code}`),
                300
              );
            }
          }
        })
        .catch(() => setShowError(true));
    }
  });

  useEffect(() => {
    // カード番号とPINが入っていない場
    if (!state || !card_no || !pin_code) navigate('/connect');
  }, []);

  return (
    <FullHeightLayout white>
      {Object.keys(errors).length !== 0 && (
        <div className="mb-4">
          {Object.entries(errors).map((error, ind) => {
            const isCardValidate =
              error[0] === 'cardNumber' && error[1].type === 'custom';

            return (
              <AlertMessage
                type="error"
                message={error[1].message as string}
                key={ind}
                code={isCardValidate ? errCode : undefined}
              />
            );
          })}
        </div>
      )}
      <Stack as="form" onSubmit={onSubmit}>
        <Row>
          <Col md={7} className="mx-auto">
            <TextInput
              id="cardNumber"
              label="カード番号"
              defaultValue={card_no}
              className="label-light"
              readOnly={true}
              {...register('cardNumber')}
            />
            <p className="form-label">生年月日</p>
            <Row>
              <Form.Group as={Col} className="d-flex align-items-baseline">
                <TextInput
                  id="birthYear"
                  type="tel"
                  placeholder="例：1990"
                  {...register('birthYear')}
                />
                <span className="ms-2">年</span>
              </Form.Group>
              <Form.Group as={Col} className="d-flex align-items-baseline">
                <TextInput
                  id="birthMonth"
                  type="tel"
                  placeholder="例：1"
                  {...register('birthMonth')}
                />
                <span className="ms-2">月</span>
              </Form.Group>
              <Form.Group as={Col} className="d-flex align-items-baseline">
                <TextInput
                  id="birthDay"
                  type="tel"
                  placeholder="例：1"
                  {...register('birthDay')}
                />
                <span className="ms-2">日</span>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="bottom-fix mb-3">
          <Col md={7} className="mx-auto">
            <Stack>
              {isProcessing ? (
                <LoadingButton />
              ) : (
                <SubmitButton text="カードを連携する" className="js-test" />
              )}
            </Stack>
          </Col>
        </Row>
        <ErrorModal
          status={showError}
          message="こちらの機能は、23:00から7:00までご利用になれません。"
          handleClose={handleClose}
        />
      </Stack>
    </FullHeightLayout>
  );
};
export default CardValidate;
