import React, { useEffect, useState } from 'react';
import { Stack, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';

import { updateAction } from 'lib/stateActions';
import { API_SUCCEEDED } from 'lib/constants';
import { GENDERS } from 'lib';
import { registerUser, registerMyStore } from 'api';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LoadingButton, LinkButton, SubmitButton } from 'component/Button';
import FormReviewItem from 'component/FormReviewItem';
import { RegisterUserType } from 'types/UserType';
import ErrorModal from 'component/ErrorModal';

import { ReactComponent as MapPin } from 'assets/image/icons/MapPin.svg';

interface LocationStateType {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

/** 会員証発行フォーム入力確認画面 */
const CardRegisterConfirm: React.FC = () => {
  PageTitle('会員証新規発行');

  const navigate = useNavigate();
  const location = useLocation();

  const [hasCard, setHasCard] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isProcessing, setIsProccessing] = useState<boolean>(false);
  const { state } = useStateMachine({ updateAction });
  const { registerCardInput, store } = state;

  const handleClose = () => setShowError(false);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsProccessing(true);
    const payload: RegisterUserType = {
      birthday: `${registerCardInput.birthYear}/${registerCardInput.birthMonth}/${registerCardInput.birthDay}`,
      first_name: registerCardInput.firstName,
      last_name: registerCardInput.lastName,
      first_name_kana: registerCardInput.firstNameKana,
      last_name_kana: registerCardInput.lastNameKana,
      gender: registerCardInput.gender,
      tel: registerCardInput.tel,
      postal_code: registerCardInput.zipcode,
      address_1: registerCardInput.address1,
      address_2: registerCardInput.address2,
      enrollment_store_id: parseInt(store.id),
      ...(location.state as LocationStateType)
    };

    if (registerCardInput.cardNumber !== '') {
      payload['card_no'] = registerCardInput.cardNumber;
    }

    registerUser(payload)
      .then((res) => {
        if (res.status === API_SUCCEEDED) {
          registerMyStore(parseInt(store.id));
          setTimeout(() => navigate('/register/complete'), 300);
        } else {
          /** エラーコードを参照して、エラーメッセージを設定する */
          const code = res.error_code;
          setTimeout(
            () => navigate(`/register/complete?fail=true&code=${code}`),
            300
          );
        }
      })
      .catch(() => setShowError(true));
  };

  useEffect(() => {
    if (registerCardInput.cardNumber !== '') {
      setHasCard(true);
    }
  }, []);

  return (
    <FullHeightLayout white className="px-0">
      <Stack as="form" onSubmit={handleSubmit}>
        <Row>
          <Col md={7} className="mx-auto">
            <div className="text-center my-5">
              <h3>会員情報入力確認</h3>
            </div>
            {registerCardInput.cardNumber && (
              <FormReviewItem
                botBorder
                label="カード番号"
                value={registerCardInput.cardNumber}
              />
            )}
            <FormReviewItem
              botBorder
              label="お名前"
              value={`${registerCardInput.lastName} ${registerCardInput.firstName}`}
            />
            <FormReviewItem
              botBorder
              label="お名前(カナ)"
              value={`${registerCardInput.lastNameKana} ${registerCardInput.firstNameKana}`}
            />
            <FormReviewItem
              botBorder
              label="生年月日"
              value={`${registerCardInput.birthYear}年${registerCardInput.birthMonth}月${registerCardInput.birthDay}日`}
            />
            <FormReviewItem
              botBorder
              label="性別"
              value={
                GENDERS.filter(
                  (gender) =>
                    gender.value ===
                    parseInt(registerCardInput.gender?.toString() as string)
                )[0].label
              }
            />
            <FormReviewItem
              botBorder
              label="電話番号"
              value={registerCardInput.tel}
            />
            <FormReviewItem
              botBorder
              label="郵便番号"
              value={registerCardInput.zipcode}
            />
            <FormReviewItem
              botBorder
              label="住所"
              value={`${registerCardInput.address1}${registerCardInput.address2}`}
            />
            <div className="label-light px-3 pb-3 border-bottom">
              <Stack direction="vertical" className="position-relative">
                <Form.Label>よく行く店舗</Form.Label>
                <div className="inline-block">
                  <MapPin />
                  <span className="store-card--name">{store.name}</span>
                </div>
                <span className="store-card--address">
                  〒{store.zipcode} {store.address}
                </span>
                <div
                  className="position-absolute"
                  style={{ top: '-15px', right: '10px' }}
                >
                  <LinkButton
                    link="/register/store-choice"
                    text="変更"
                    type="primary-link"
                  />
                </div>
              </Stack>
            </div>
          </Col>
        </Row>
        <Row className="bottom-fix mt-5 mb-4 mx-3">
          <Col md={7} className="mx-auto">
            <Stack gap={3}>
              {isProcessing ? (
                <LoadingButton />
              ) : (
                <SubmitButton text="登録" className="js-test" />
              )}
              <LinkButton
                link={`/register/input?has-already-card=${hasCard}`}
                type="secondary-link"
                text="情報変更へ"
                className="js-update"
              />
            </Stack>
          </Col>
        </Row>
        <ErrorModal
          status={showError}
          message="こちらの機能は、23:00から7:00までご利用になれません。"
          handleClose={handleClose}
        />
      </Stack>
    </FullHeightLayout>
  );
};
export default CardRegisterConfirm;
