import liff from '@line/liff/dist/lib';

export const GENDERS = [
  {
    label: '男性',
    text: 'male',
    value: 2
  },
  {
    label: '女性',
    text: 'female',
    value: 1
  },
  {
    label: 'その他',
    text: 'other',
    value: 0
  }
];

/**
 * @param url - url string
 * @param external - 外部ブラウザーで開く
 * @returns void
 */
export const openWindow = (url: string, external?: boolean) => {
  if (url) {
    liff.openWindow({
      url: url,
      external: external ?? false
    });
  }
};

/**
 * @param dateStr - Date string
 * @param showTime - (boolean) 時間表示 HH:MM
 * @param isJp - (boolean) 日本語フォーマット
 * @returns "YYYY/MM/DD" || "YYYY/MM/DD HH:MM" ||"YYYY年MM月DD日" || "YYYY年MM月DD日 HH:MM"
 */
export const formatDate = (
  dateStr: string,
  showTime: boolean,
  isJp: boolean
) => {
  const date = new Date(dateStr);
  const y = date.getFullYear();
  const m = (date.getMonth() + 1).toString().padStart(2, '0');
  const d = date.getDate().toString().padStart(2, '0');
  const h = date.getHours().toString().padStart(2, '0');
  const mn = date.getMinutes().toString().padStart(2, '0');

  let result = isJp ? `${y}年${m}月${d}日` : `${y}/${m}/${d}`;

  if (showTime) {
    result = `${result} ${h}:${mn}`;
  }

  return result;
};

/**
 * 文字列のByte sizeを計算する（SJIS）
 * @param str - string
 * @returns number
 */
export const countByte = (str: string) => {
  let count = 0;

  for (let i = 0; i < str.length; i++) {
    const ucode = str.charCodeAt(i);

    if (
      (ucode >= 0x0 && ucode < 0x81) ||
      ucode === 0xf8f0 ||
      (ucode >= 0xff61 && ucode < 0xffa0) ||
      (ucode >= 0xf8f1 && ucode < 0xf8f4)
    ) {
      count += 1;
    } else {
      count += 2;
    }
  }
  return count;
};

/**
 * 秒の時間を時、分、秒に変換
 * @param sec - number
 * @returns [HH, MM, SS]
 */
export const setCountTimer = (sec: number) => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;

  return [hours, minutes, seconds];
};

/**
 * 日付を期間内かどうかの確認
 * @param date - string
 * @param dateStart - string
 * @param dateEnd - string
 * @returns boolean
 */
export const checkBetweenDates = (
  date: string,
  dateStart: string,
  dateEnd: string
) => {
  const dateToCheck = new Date(date);
  const dateFrom = new Date(dateStart);
  const dateTo = new Date(dateEnd);

  return dateToCheck > dateFrom && dateToCheck < dateTo;
};
