import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Badge, Stack } from 'react-bootstrap';

import { formatDate } from 'lib';

interface NewsItemType {
  id: string;
  title: string;
  date: string;
  image?: string;
  isUnread: boolean;
}

const NewsItem: React.FC<NewsItemType> = ({
  id,
  title,
  date,
  image,
  isUnread
}) => {
  return (
    <RouterLink to={`/news/${id}`}>
      <Stack
        direction="horizontal"
        className="px-3 py-2 mb-3 section-cont section-cont--rounded-1 news-item"
      >
        <div className="me-2 news-item__image">
          {image && <img src={image} alt={title} />}
        </div>
        <Stack>
          <span className="mb-2 text--md news-item__title">
            {title}
            {/* 未読バッジ */}
            {isUnread && (
              <Badge pill bg="danger" className="align-text-top ms-1">
                未読
              </Badge>
            )}
          </span>
          <span className="text--sm text--secondary-light news-item__date">
            {formatDate(date, false, true)}
          </span>
        </Stack>
      </Stack>
    </RouterLink>
  );
};
export default NewsItem;
