import React from 'react';
import { Stack } from 'react-bootstrap';

import ClockIcon from 'assets/image/icons/clock.svg';
import TelIcon from 'assets/image/icons/tel.svg';
import ChevronRightIcon from 'assets/image/icons/chevron_right.svg';
import LineIcon from 'assets/image/icons/line_icon.png';

interface StoreInfoType {
  store: string;
  address: string;
  time: string;
  tel: string;
  link?: string;
  className?: string;
  lineAccountUrl?: string;
}

const StoreInfo: React.FC<StoreInfoType> = ({
  store,
  address,
  time,
  tel,
  link,
  className,
  lineAccountUrl,
  children
}) => {
  let classList =
    'store-card section-cont section-cont--rounded-1 my-2 py-2 px-3';
  className && (classList += ` ${className}`);
  return (
    <Stack className={classList}>
      <div className="position-relative">
        <div className={lineAccountUrl && 'store-card__top'}>
          {link ? (
            <Stack direction="horizontal" as="a" href={link}>
              <p className="mb-1 text--md">{store}</p>
              <img src={ChevronRightIcon} alt="" className="ms-2" />
            </Stack>
          ) : (
            <p className="mb-1 text--md">{store}</p>
          )}
          <p className="mb-3 text--secondary-light">{address}</p>
        </div>
        {lineAccountUrl && (
          <a className="position-absolute end-0 top-0" href={lineAccountUrl}>
            <img
              className="line-account-icon ratio-1x1"
              src={LineIcon}
              alt={`${store}公式LINEアカウント`}
            />
          </a>
        )}
      </div>
      <p className="mb-1 text--secondary-light">
        <img src={ClockIcon} alt="" className="me-1" />
        {time}
      </p>
      <p className="mb-2 text--secondary-light">
        <img src={TelIcon} alt="" className="me-1" />
        {tel}
      </p>
      <Stack direction="horizontal" className="my-2 w-100">
        {children}
      </Stack>
    </Stack>
  );
};
export default StoreInfo;
