import React from 'react';
import { Row, Col } from 'react-bootstrap';

import MainLayout from 'layout/MainLayout';

const NotFound: React.FC = () => {
  return (
    <MainLayout>
      <Row className="row">
        <Col md={7} className="mx-auto">
          <h1 className="text-center">404 Not Found</h1>
          <h3 className="text-center">ページが見つかりませんでした。</h3>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default NotFound;
