import React, { useState } from 'react';
import { Stack, Row, Col, InputGroup, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useStateMachine } from 'little-state-machine';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import postal_code from 'japan-postal-code';
import { toHalfwidthKana, toAscii } from 'japanese-string-utils';

import { updateAction } from 'lib/stateActions';
import { GENDERS, openWindow, countByte } from 'lib';
import { API_SUCCEEDED } from 'lib/constants';
import { errorCodes } from 'lib/errorCodes';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import TextInput from 'component/TextInput';
import { LoadingButton, SubmitButton } from 'component/Button';
import RadioInput from 'component/RadioInput';
import AlertMessage from 'component/AlertMessage';

import { verifyCardExist } from 'api';

type FormValuesType = {
  cardNumber: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  birthYear: string;
  birthMonth: string;
  birthDay: string;
  gender: number;
  tel: string;
  zipcode: string;
  address1: string;
  address2: string;
  agree: boolean;
};

interface LocationStateType {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
}

/**
 * 会員証発行フォーム（会員情報入力）画面：以下のケースで表示される
 * - マキヤポイントカード（白カード・プラスチックカード）所有確認画面で「いいえ、持ってません」クリック後 has-already-card=false
 * - 会員証発行（アプリ移行）の注意事項確認画面で「確認しました」クリック後 has-already-card=true
 * - 会員証発行フォーム入力内容確認画面で「情報変更へ」クリック後 has-already-card=${hasCard}
 */
const CardRegisterInput: React.FC = () => {
  PageTitle('会員証新規発行');

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { actions, state } = useStateMachine({ updateAction });

  const [errCode, setErrCode] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const hasAlreadyCard =
    searchParams.get('has-already-card') === 'true' ? true : false;

  const validationSchema = yup.object().shape({
    hasAlreadyCard: yup.boolean().default(hasAlreadyCard),
    cardNumber: yup.string().when('hasAlreadyCard', {
      is: true,
      then: yup
        .string()
        .required('「カード番号」が入力されていません')
        .min(13, '正しいカード番号を入力してください')
        .max(13, '正しいカード番号を入力してください')
        .matches(/^(2200|2290)[0-9]*$/, '正しいカード番号を入力してください')
    }),
    lastName: yup
      .string()
      .required('「姓」が入力されていません')
      .matches(/^[^"',<>&\s*]+$/, '「姓」にスペースと特殊文字は使用できません')
      .when('firstName', (firstName, schema) => {
        return schema.test({
          test: (lastName: string) => countByte(firstName + lastName) <= 23,
          message: '「姓」と「名」の文字数がオーバーしています'
        });
      }),
    firstName: yup
      .string()
      .required('「名」が入力されていません')
      .matches(/^[^"',<>&\s*]+$/, '「名」にスペースと特殊文字は使用できません'),
    lastNameKana: yup
      .string()
      .required('「姓(カナ)」が入力されていません')
      .matches(
        /^[^"',<>&\s*]+$/,
        '「姓(カナ)」にスペースと特殊文字は使用できません'
      )
      .matches(
        /^[^\u3040-\u309f\u4e00-\u9fafa-zA-Z]+$/,
        '「姓(カナ)」は全角カナで入力してください'
      )
      .when('firstNameKana', (firstNameKana, schema) => {
        return schema.test({
          test: (lastNameKana: string) => {
            const firstNameAscii = toAscii(firstNameKana);
            const lastNameAscii = toAscii(lastNameKana);
            const firstNameHalfWidth = toHalfwidthKana(firstNameAscii);
            const lastNameHalfWidth = toHalfwidthKana(lastNameAscii);
            return countByte(lastNameHalfWidth + firstNameHalfWidth) <= 23;
          },
          message: '「姓(カナ)」と「名(カナ)」の文字数がオーバーしています'
        });
      }),
    firstNameKana: yup
      .string()
      .required('「名(カナ)」が入力されていません')
      .matches(
        /^[^"',<>&\s*]+$/,
        '「名(カナ)」にスペースと特殊文字は使用できません'
      )
      .matches(
        /^[^\u3040-\u309f\u4e00-\u9fafa-zA-Z]+$/,
        '「名(カナ)」は全角カナで入力してください'
      ),
    birthYear: yup
      .string()
      .required('「生年月日(年)」が入力されていません')
      .max(4, '正しい生年月日(年)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(年)を入力してください')
      .when(['birthMonth', 'birthDay'], {
        is: (birthMonth: string, birthDay: string) => birthMonth && birthDay,
        then: yup
          .string()
          .test('birthYear', '正しい生年月日を入力してください', function (y) {
            const m = this.parent.birthMonth;
            const d = this.parent.birthDay;

            const date = new Date(`${y}/${m}/${d}`);

            if (
              (date.getFullYear() === 1900 &&
                date.getMonth() + 1 === 1 &&
                date.getDate() === 1) ||
              date.getFullYear() < 1900
            ) {
              return false;
            } else {
              return (
                date.getFullYear() === parseInt(y as string) &&
                date.getMonth() + 1 === parseInt(m) &&
                date.getDate() === parseInt(d)
              );
            }
          })
      }),
    birthMonth: yup
      .string()
      .required('「生年月日(月)」が入力されていません')
      .max(2, '正しい生年月日(月)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(月)を入力してください'),
    birthDay: yup
      .string()
      .required('「生年月日(日)」が入力されていません')
      .max(2, '正しい生年月日(日)を入力してください')
      .matches(/^[0-9]*$/, '正しい生年月日(日)を入力してください'),
    gender: yup.string().nullable().required('「性別」を選択してください'),
    tel: yup
      .string()
      .required('「電話番号」が入力されていません')
      .min(10, '正しい電話番号を入力してください')
      .max(11, '正しい電話番号を入力してください')
      .matches(/^[0-9]*$/, '正しい電話番号を入力してください'),
    zipcode: yup
      .string()
      .required('「郵便番号」が入力されていません')
      .min(7, '正しい郵便番号を入力してください')
      .max(7, '正しい郵便番号を入力してください')
      .matches(/^[0-9]*$/, '正しい郵便番号を入力してください')
      .test('valid-zip', '正しい郵便番号を入力してください', (val) => {
        let valid = false;
        postal_code.get(val as string, function (address) {
          valid = Object.keys(address).length !== 0;
        });
        return valid;
      }),
    address1: yup
      .string()
      .required('「住所１」が入力されていません')
      .matches(/^[^"',<>&]+$/, '「住所１」に特殊文字は使用できません')
      .test(
        'check-byte',
        '「住所１」は文字数オーバーです',
        (val) => countByte(val as string) <= 40
      ),
    address2: yup
      .string()
      .required('「住所２」が入力されていません')
      .matches(/^[^"',<>&]+$/, '「住所２」に特殊文字は使用できません')
      .test(
        'check-byte',
        '「住所２」は文字数オーバーです',
        (val) => countByte(val as string) <= 40
      ),
    agree: yup
      .boolean()
      .oneOf([true], '会員規約の同意にチェックを入れてください')
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors }
  } = useForm<FormValuesType>({
    criteriaMode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = handleSubmit((data) => {
    if (!data.cardNumber) {
      data.cardNumber = state.registerCardInput.cardNumber ?? '';
    }
    actions.updateAction({ ...state, registerCardInput: data });

    if (hasAlreadyCard) {
      verifyCardExist(data.cardNumber as string).then((res) => {
        if (res.status === API_SUCCEEDED) {
          navigate('/register/store-choice', {
            state: { ...(location.state as LocationStateType) }
          });
        } else {
          /** エラーコードを参照して、エラーメッセージを設定する */
          const code = res.error_code as keyof typeof errorCodes;
          const formError = ['008', '009', '010'];

          if (formError.includes(code)) {
            /** フォームのエラメッセージとして出す */
            setError('cardNumber', {
              type: 'custom',
              message: errorCodes[code].desc
            });
            setErrCode(code);
            setTimeout(() => setIsProcessing(false), 300);
          } else {
            /** 失敗画面に移動 */
            setTimeout(() => navigate(`/complete?fail=true&code=${code}`), 300);
          }
        }
      });
    } else {
      navigate('/register/store-choice', {
        state: { ...(location.state as LocationStateType) }
      });
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setValue('zipcode', target.value);

    postal_code.get(target.value, function (address) {
      setValue('address1', address.prefecture + address.city + address.area);
    });
  };

  return (
    <>
      {Object.entries(errors).map((error, ind) => {
        const isCardValidate =
          error[0] === 'cardNumber' && error[1].type === 'custom';
        return (
          <AlertMessage
            type="error"
            message={error[1].message as string}
            key={ind}
            code={isCardValidate ? errCode : undefined}
          />
        );
      })}

      <FullHeightLayout white>
        <Stack as="form" onSubmit={onSubmit}>
          <Row>
            <Col md={7} className="mx-auto">
              {hasAlreadyCard && (
                <TextInput
                  type="tel"
                  id="cardNumber"
                  label="カード番号"
                  placeholder="例：2200000000000"
                  defaultValue={state.registerCardInput.cardNumber}
                  caption="※ポイント移行のため、正しいカード番号を入力してください。"
                  {...register('cardNumber')}
                />
              )}
              <Stack direction="horizontal" gap={3} className="w-100">
                <TextInput
                  id="lastName"
                  label="姓"
                  placeholder="例：新規"
                  className="w-50"
                  defaultValue={state.registerCardInput.lastName}
                  {...register('lastName')}
                />
                <TextInput
                  id="firstName"
                  label="名"
                  placeholder="例：登録"
                  className="w-50"
                  defaultValue={state.registerCardInput.firstName}
                  {...register('firstName')}
                />
              </Stack>
              <Stack direction="horizontal" gap={3} className="w-100">
                <TextInput
                  id="lastNameKana"
                  label="姓 (カナ)"
                  placeholder="例：シンキ"
                  className="w-50"
                  defaultValue={state.registerCardInput.lastNameKana}
                  {...register('lastNameKana')}
                />
                <TextInput
                  id="firstNameKana"
                  label="名 (カナ)"
                  placeholder="例：トウロク"
                  className="w-50"
                  defaultValue={state.registerCardInput.firstNameKana}
                  {...register('firstNameKana')}
                />
              </Stack>

              <p className="form-label">生年月日</p>
              <Stack direction="horizontal" gap={3} className="w-100">
                <InputGroup className="mb-3 text-input-group--clear">
                  <TextInput
                    id="birthYear"
                    type="tel"
                    placeholder="例：1990"
                    defaultValue={state.registerCardInput.birthYear}
                    {...register('birthYear')}
                  />
                  <InputGroup.Text className="mb-3">年</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 text-input-group--clear">
                  <TextInput
                    id="birthMonth"
                    type="tel"
                    placeholder="例：1"
                    defaultValue={state.registerCardInput.birthMonth}
                    {...register('birthMonth')}
                  />
                  <InputGroup.Text className="mb-3">月</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 text-input-group--clear">
                  <TextInput
                    id="birthDay"
                    type="tel"
                    placeholder="例：1"
                    defaultValue={state.registerCardInput.birthDay}
                    {...register('birthDay')}
                  />
                  <InputGroup.Text className="mb-3">日</InputGroup.Text>
                </InputGroup>
              </Stack>

              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Form.Group className="mb-3" controlId={field.name}>
                    <Form.Label className="d-block">性別</Form.Label>
                    {GENDERS.map((option) => (
                      <RadioInput
                        key={`gender-${option.value}`}
                        id="gender"
                        label={option.label}
                        value={option.value}
                        defaultChecked={
                          state.registerCardInput.gender === option.value
                        }
                        {...register('gender')}
                      />
                    ))}
                  </Form.Group>
                )}
              />

              <TextInput
                id="tel"
                type="tel"
                label="電話番号"
                placeholder="例：08000000000"
                defaultValue={state.registerCardInput.tel}
                caption="※ハイフン（-）は不要です。"
                {...register('tel')}
              />
              <TextInput
                id="zipcode"
                type="tel"
                label="郵便番号"
                placeholder="例：0000000"
                defaultValue={state.registerCardInput.zipcode}
                caption="※ハイフン（-）は不要です。"
                {...register('zipcode')}
                onChange={handleChange}
              />
              <TextInput
                id="address1"
                label="住所１"
                placeholder="例：◯◯県◯◯市"
                caption="※都道府県と市区町村をご入力ください"
                defaultValue={state.registerCardInput.address1}
                {...register('address1')}
              />
              <TextInput
                id="address2"
                label="住所２"
                placeholder="例：〇〇〇〇マンション120号"
                caption="※番地以降をご入力ください。"
                defaultValue={state.registerCardInput.address2}
                {...register('address2')}
              />
              <Form.Check
                className="text-center w-100 d-inline-flex justify-content-center gap-3"
                type="checkbox"
              >
                <Form.Check.Input type="checkbox" {...register('agree')} />
                <Form.Check.Label>
                  <a
                    href="#"
                    className="text--primary"
                    onClick={() =>
                      openWindow(
                        'https://www.makiya-group.co.jp/espot/news/terms.html'
                      )
                    }
                  >
                    会員規約
                  </a>
                  <span className="ml-2">を確認して同意します。</span>
                </Form.Check.Label>
              </Form.Check>
            </Col>
          </Row>
          <Row>
            <Col md={7} className="mx-auto">
              <Stack className="mt-5 mb-0">
                {isProcessing ? (
                  <LoadingButton />
                ) : (
                  <SubmitButton
                    text="次へ"
                    className="js-create"
                    onClick={() => window.scrollTo(0, 0)}
                  />
                )}
              </Stack>
            </Col>
          </Row>
        </Stack>
      </FullHeightLayout>
    </>
  );
};
export default CardRegisterInput;
