import React from 'react';

import { CenterModal } from './Modals';
import { LinkButton } from './Button';

interface ErrorModalType {
  status: boolean;
  message: string;
  handleClose: React.MouseEventHandler<HTMLAnchorElement>;
}

const ErrorModal: React.FC<ErrorModalType> = ({
  status,
  message,
  handleClose
}) => {
  return (
    <CenterModal status={status}>
      <p className="mb-0 text-center text--lg text--secondary">メンテナンス</p>
      <p className="text-center my-4">{message}</p>
      <div className="mb-2"></div>
      <LinkButton link="#" text="閉じる" onClick={handleClose} />
    </CenterModal>
  );
};
export default ErrorModal;
