import React, { useEffect, useState } from 'react';
import { Stack, Row, Col } from 'react-bootstrap';

import { getCurrentUser } from 'api';
import { API_SUCCEEDED } from 'lib/constants';

import FullHeightLayout from 'layout/FullHeightLayout';
import PageTitle from 'component/PageTitle';
import { LinkButton } from 'component/Button';

/** カード変更画面 */
const CardReissue: React.FC = () => {
  PageTitle('カード変更');
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) setIsDone(true);
    });
  }, []);

  return (
    <FullHeightLayout white>
      <Row>
        <Col md={7} className="mx-auto">
          {isDone && (
            <Stack className="mt-5 text-center" gap={4}>
              <p className="mb-0 ps-3 text-decoration-underline text-start text-secondary">
                カードを紛失した方
              </p>
              <p className="mb-1 text-secondary">
                プラスチックカードの再発行が不要な方はこちら
              </p>
              <LinkButton
                link="confirm"
                replace={true}
                disabled
                type="primary-outline"
                text="新しいデジタルカードを発行する"
              />
              <p className="mb-0 text-center text--primary row--no-mt">
                ※現在このサービスはご利用いただけません。
              </p>
              <p className="mt-1 ps-3 text-start text-secondary">
                ※プラスチックカードの再発行が必要な方は、店舗にて紛失届をご記入ください。再発行には1週間程のお時間とカード発行手数料の100円（税込）がかかります。
              </p>
              <div className="my-2 text-center line-through">
                <span className="bg-white px-3 text--secondary-light text--sm">
                  または
                </span>
              </div>
              <p className="mb-0 ps-3 text-decoration-underline text-start text-secondary">
                カードを再発行した方
              </p>
              <p className="mb-1 ps-3 text-secondary text-start">
                再発行したカードをお持ちで、お客様情報登録が完了した方はこちら
              </p>
              <LinkButton
                link="/connect?event=reissue"
                type="secondary-outline"
                text="別のカードを連携する"
              />
              <p className="mb-0 ps-3 text-start text-secondary">
                ※お客様情報の登録は、店舗で再発行の申請を頂いてから3日程度で完了します。
              </p>
            </Stack>
          )}
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default CardReissue;
