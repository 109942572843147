import { getCurrentPrepaid } from 'api';
import { useStateMachine } from 'little-state-machine';
import { useAsyncFn, useEffectOnce } from 'react-use';
import { updateAction } from '../stateActions';
import { API_SUCCEEDED } from '../constants';
import { useRef } from 'react';

/**
 * マキヤプリカ残高取得API実行を制御し、
 * API取得結果もしくはsession storageに保存されたマキヤプリカ残高を返却するhooks
  
 * @param initFetch 初期表示時にAPIを実行するかのフラグ
 * @return
 *  prepaidBalanceTotal: API実行前は、little-state-machineに保存していたマキヤプリカ残高情報を返却。API実行後はAPIから取得してきた値を返却\
 *  isLoadingGetPrepaid: マキヤプリカ残高取得API実行中フラグ\
 *  executeCurrentPrepaid: マキヤプリカ残高取得API実行関数
 */
const usePrepaidBalanceTotal = (
  initFetch: boolean
): {
  prepaidBalanceTotal?: number;
  isLoadingGetPrepaid: boolean;
  executeCurrentPrepaid: () => Promise<void>;
} => {
  const apiExecuted = useRef(false);
  const { actions, state } = useStateMachine({ updateAction });

  const [
    { value: resBalanceTotal, loading: isLoadingGetPrepaid },
    executeCurrentPrepaid
  ] = useAsyncFn(async () => {
    const res = await getCurrentPrepaid();

    // APIを実行済みなので更新する
    apiExecuted.current = true;

    const balance_total =
      typeof res.data?.balance_total === 'number' ? res.data.balance_total : 0;

    // API実行に成功した時に値を保存する
    if (res?.status === API_SUCCEEDED) {
      actions.updateAction({
        ...state,
        prepaidBalanceTotal: balance_total
      });
      return balance_total;
    }

    // API失敗の場合はstateを返却
    return state.prepaidBalanceTotal;
  }, [state, actions]);

  useEffectOnce(() => {
    // 初期表示時にlittle-state-machineに値が保存されていない場合、
    // もしくは、初期表示時にAPIを実行する設定になっていれば、
    // APIを実行する
    if (typeof state.prepaidBalanceTotal === 'undefined' || initFetch) {
      executeCurrentPrepaid();
    }
  });

  return {
    prepaidBalanceTotal: apiExecuted.current
      ? resBalanceTotal
      : state.prepaidBalanceTotal,
    isLoadingGetPrepaid,
    executeCurrentPrepaid
  };
};

export default usePrepaidBalanceTotal;
