import { postDigiclueplus } from 'api';
import PageTitle from 'component/PageTitle';
import MainLayout from 'layout/MainLayout';
import { openWindow } from 'lib';
import { FC } from 'react';
import { Button, Col, Row, Stack, Image } from 'react-bootstrap';
import { useAsyncFn } from 'react-use';
import campaignExplanation from 'assets/image/campaign_explanation.png';

const DigiclueplusCampaign: FC = () => {
  PageTitle('キャンペーン');
  const [{ loading }, onClickNextButton] = useAsyncFn(async () => {
    const res = await postDigiclueplus('stamps');
    if (!res || res.status === 'failed')
      return window.location.replace(process.env.PUBLIC_URL + '/error/500');
    openWindow(res.data.service_url, false);
  }, []);

  return (
    <MainLayout footer>
      <Row className="pt-4">
        <Col md={7} className="mx-auto">
          <Stack gap={3}>
            <Image src={campaignExplanation} alt="キャンペーン説明画像" fluid />
            <p className="mb-4 text--md p-0">
              【 注意事項をご確認の上、ご利用ください】
            </p>
            {/* 注意事項 */}
            <Stack className="mb-4" gap={3}>
              <p className="mb-0 text--md">
                ●キャンペーンは、開催していない期間もございます。ご容赦ください。
              </p>
              <p className="mb-0 text--md">
                ●下の「キャンペーンを見る」から、開催中のキャンペーンを確認し、エントリーを行ってください。
              </p>
              <p className="mb-0 text--md">
                ●各キャンペーンの詳細・注意事項をご確認の上、お買い物をお楽しみください。
              </p>
              <p className="mb-0 text--md">
                ●2024年7月4日から、新横浜店限定にてスタンプキャンペーンを開催します。
              </p>
            </Stack>

            <Button
              variant="-primary"
              className="py-3 btn--custom"
              disabled={loading}
              onClick={onClickNextButton}
            >
              キャンペーンを見る
            </Button>
          </Stack>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default DigiclueplusCampaign;
