import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PageTitle from 'component/PageTitle';
import FullHeightLayout from 'layout/FullHeightLayout';
import { ReactComponent as ErrorClose } from 'assets/image/icons/error_close.svg';

const ServerError: React.FC = () => {
  PageTitle('サーバーエラ');

  return (
    <FullHeightLayout center className="error-500">
      <div className="error-500-wrapper">
        <div className="error-500__close-icon">
          <ErrorClose />
        </div>
        <h2 className="error-500__title">
          サーバーエラーが
          <br />
          発生しました
        </h2>
        <p className="error-500__text">
          「トップへ戻る」ボタンから
          <br />
          やり直してください。
        </p>
        <RouterLink to="/" className="error-500__return">
          トップへ戻る
        </RouterLink>
      </div>
    </FullHeightLayout>
  );
};

export default ServerError;
