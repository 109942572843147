import React, { useEffect, useState } from 'react';

import Login from './Login';
import CardHome from './CardHome';

import { getCurrentUser } from 'api';
import { API_SUCCEEDED } from 'lib/constants';

const Top: React.FC = () => {
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);

  useEffect(() => {
    getCurrentUser().then((res) => {
      if (res.status === API_SUCCEEDED) {
        setIsRegistered(true);
      }
      setIsFinished(true);
    });
  }, []);

  return (
    <> {isFinished && <> {!isRegistered ? <Login /> : <CardHome />} </>} </>
  );
};
export default Top;
