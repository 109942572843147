export const LIFF_ID = process.env.REACT_APP_LIFF_ID as string;

export const LIFF_ENDPOINT = process.env.REACT_APP_LIFF_ENDPOINT as string;

export const LIFF_URL = process.env.REACT_APP_LIFF_URL as string;

export const SMARTRECEIPT_LINK = process.env
  .REACT_APP_SMARTRECEIPT_LINK as string;

export const PRODUCT_RESERVATION_LINK = process.env
  .REACT_APP_PRODUCT_RESERVATION_LINK as string;

export const GA_TRACKING_ID = process.env.REACT_APP_GTM_ID;

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const API_SUCCEEDED = 'succeeded';
export const API_FAILED = 'failed';

export const EBASE_APP_URL =
  'https://enavi-espot.ebase-jp.com/eshokujyudx/DispItemSearch.do?volumeName=00031';

/**
 * セッション切れ画面のパス
 */
export const SESSION_EXPIRED_PAGE_PATH = '/session-expired';

/**
 * 外部のクレジットカードチャージサイトへの移動画面のパス
 */
export const ONLINE_CHARGE_TRANSITION_PAGE_PATH = '/online-charge';

/**
 * 外部のクレジットカードチャージサイトへの移動画面のURL
 */
export const ONLINE_CHARGE_TRANSITION_PAGE_URL = `${LIFF_ENDPOINT}${ONLINE_CHARGE_TRANSITION_PAGE_PATH}`;

/**
 * 外部のクレジットカードチャージサイトのURL
 * 開発環境は存在しない（本番環境）
 * https://www.notion.so/digiclue/1Q-b9deee1b4b784a1081190441e131e658?pvs=4#819c1034ea1a434d87a9cf5439c33b32
 */
export const ONLINE_CHARGE_URL = 'https://makiya.onlinecharge.jp';

/**
 * 外部のクレジットカードチャージサイトにログインする際に利用するカード番号の先頭につける番号
 * https://www.notion.so/digiclue/1Q-b9deee1b4b784a1081190441e131e658?pvs=4#fe1c7f718bf74b7a9120fa793521287f
 */
export const ONLINE_CHARGE_CARD_NO_PREFIX = 881;

/**
 * 表示する広告ポップアップの外部スクリプトURL
 */
export const POPUP_AD_SRC =
  'https://gcmadv-cdn.goldspotmedia.com/delivery/ad/2715d421-0ffd-4549-8f76-8a3dee861d42?gi=gcmInfo_2715d4210ffd45498f768a3dee861d42';
export const POPUP_GCM_SRC =
  'https://gcmast-cdn.goldspotmedia.com/libs/gcm-player/1.10.1/gcm-player.min.js?tg=gcmInfo_2715d4210ffd45498f768a3dee861d42&tm=gcmMediaInfo_2715d4210ffd45498f768a3dee861d42';

/**
 * 表示する広告バナーの変数
 */
export const AD_BANNERS = [
  {
    fluctGroupId: '1000175749',
    fluctUnitName: 'fluct-unit-1000279899-1',
    fluctUnitId: '1000279899'
  },
  {
    fluctGroupId: '1000175750',
    fluctUnitName: 'fluct-unit-1000279900-1',
    fluctUnitId: '1000279900'
  },
  {
    fluctGroupId: '1000175751',
    fluctUnitName: 'fluct-unit-1000279901-1',
    fluctUnitId: '1000279901'
  },
  {
    fluctGroupId: '1000175752',
    fluctUnitName: 'fluct-unit-1000279902-1',
    fluctUnitId: '1000279902'
  }
];
