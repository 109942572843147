import React from 'react';
import { Container, Stack } from 'react-bootstrap';

interface FullHeightLayoutType {
  center?: true;
  white?: true;
  className?: string;
}

const FullHeightLayout: React.FC<FullHeightLayoutType> = ({
  center,
  className,
  white,
  children
}) => {
  let classList = 'd-flex py-4 mw-100 full-height';
  white && (classList += ' bg-white');
  className && (classList += ` ${className}`);

  return (
    <Container className={classList}>
      <Stack className={center && 'align-self-center'}>{children}</Stack>
    </Container>
  );
};
export default FullHeightLayout;
