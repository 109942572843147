import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stack, Row, Col } from 'react-bootstrap';

import CardImage from 'assets/image/card_img.png';

import FullHeightLayout from 'layout/FullHeightLayout';
import { LinkButton } from 'component/Button';
import ErrorModal from 'component/ErrorModal';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Tokyo');

/** 初回起動画面 */
const Login: React.FC = () => {
  const [showError, setShowError] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const utmSource = searchParams.get('utm_source') || '';
  const utmCampaign = searchParams.get('utm_campaign') || '';
  const utmContent = searchParams.get('utm_content') || '';
  const utmMedium = searchParams.get('utm_medium') || '';

  const handleClose = () => setShowError(false);
  const checkLimitTime = (e: React.MouseEvent) => {
    const maintenanceStartTime = dayjs().hour(23);
    const maintenanceEndTime = dayjs().hour(7).minute(30);
    const currentTime = dayjs();
    const isInLimitedTime =
      currentTime >= maintenanceStartTime || currentTime < maintenanceEndTime;

    // NOTE: 23:00〜7:29:59AMまでは次の画面に遷移しないように防ぐ
    if (!isInLimitedTime) return;

    e.preventDefault();
    setShowError(true);
  };

  return (
    <FullHeightLayout center white>
      <Row>
        <Col md={7} className="mx-auto">
          <Stack>
            <img src={CardImage} alt="" className="w-100 mb-3" />
            <LinkButton
              onClick={checkLimitTime}
              link="/connect"
              text="すでにマキヤプリカをお持ちの方"
              state={{
                utm_source: utmSource,
                utm_campaign: utmCampaign,
                utm_content: utmContent,
                utm_medium: utmMedium
              }}
            />
            <div className="my-4 text-center line-through">
              <span className="bg-white px-3 text--secondary-light text--sm">
                または
              </span>
            </div>
            <LinkButton
              onClick={checkLimitTime}
              type="secondary-outline"
              link="/register"
              text="お持ちでない方"
              state={{
                utm_source: utmSource,
                utm_campaign: utmCampaign,
                utm_content: utmContent,
                utm_medium: utmMedium
              }}
            />
            <p className="mt-2 text-center text--secondary-light text--sm">
              新規登録、マキヤポイントカードをお持ちの方はこちらへ
            </p>
            <p className="mt-2 text-center text--secondary-light text--sm">
              ※登録は7:30~23:00の間ご利用いただけます。
            </p>
          </Stack>
          <ErrorModal
            status={showError}
            message="こちらの機能は、23:00から翌日7:30までご利用になれません。"
            handleClose={handleClose}
          />
        </Col>
      </Row>
    </FullHeightLayout>
  );
};
export default Login;
